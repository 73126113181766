import React from "react"

const SongsCount = ({ count }) => {
    return (
        <div className="list-count">
            {count}
            {count === 0 ? ' písní' : ''}
            {count === 1 ? ' píseň' : ''}
            {(count > 1 && count < 5) ? ' písně' : ''}
            {(count >= 5) ? ' písní' : ''}
        </div>
    )
}

export default SongsCount
