import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_ERROR, ADD_TO_PLAYLIST, REMOVE_FROM_PLAYLIST, UPDATE_USER, REMOVE_USER, SET_USER } from '../actionTypes';

const initialState = {
    loggedIn: false,
    user: null,
    playlist: [],
    publicUser: null,
    publicPlaylist: [],
    access_token: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER: {
            localStorage.setItem('access_token', action.payload.access_token)
            return {
                ...state,
                loggedIn: true,
                user: action.payload.user,
                playlist: action.payload.playlist,
                access_token: action.payload.access_token
            };
        }

        case SET_USER: {
            return {
                ...state,
                publicUser: action.payload.user,
                publicPlaylist: action.payload.playlist
            };
        }

        case LOGOUT_USER: {
            localStorage.removeItem('access_token')
            return initialState;
        }

        case LOGIN_USER_ERROR: {
            localStorage.removeItem('access_token')
            //alert(action.payload.message);
            alert('Přihlášení se nezdařilo.')
            return initialState;
        }

        case REMOVE_USER: {
            localStorage.removeItem('access_token')
            return initialState;
        }


        case UPDATE_USER: {
            return {
                ...state,
                user: action.payload,
            };
        }


        case ADD_TO_PLAYLIST: {

            let playlist = [...state.playlist];
            playlist.push(action.payload)

            return {
                ...state,
                playlist: playlist
            };
        }

        case REMOVE_FROM_PLAYLIST: {

            let playlist = [...state.playlist];
            playlist = playlist.filter(function (p) { return p !== action.payload })

            return {
                ...state,
                playlist: playlist
            };
        }

        default:
            return state;
    }
}
