import React, { useState } from 'react';
import { parseSong } from "../redux/actions";
import { useDispatch, connect } from "react-redux";

const AddSongUsingUrl = ({ props, parseSong }) => {

    const [url, setUrl] = useState('');
    const [valid, setValid] = useState(false);
    /* Redux dispatch */
    const dispatch = useDispatch();


    const setUrlAndValidate = (url) => {
        setUrl(url);

        var ok = false;
        if (url.indexOf('pisnicky-akordy.cz') >= 0 || url.indexOf('ukutabs.com') >= 0) {
            ok = true;
        }

        setValid(ok);
    }

    const parseUrl = async (e) => {
        e.preventDefault();
        const parsedSong = await parseSong(url);

        //dispatch({ type: "UPDATE_CHORDS", payload: { songId: 0, chords: parsedSong.chords } });
        //dispatch({ type: "UPDATE_ROWS", payload: { songId: 0, rows: parsedSong.rows } });
        //dispatch({ type: "UPDATE_NAME", payload: { songId: 0, name: parsedSong.name } });
        //dispatch({ type: "UPDATE_AUTHOR", payload: { songId: 0, author: parsedSong.author } });
        //dispatch({ type: "UPDATE_TRANSPOSITION", payload: { songId: 0, transposition: parsedSong.transposition } });

        props.onParsed(parsedSong);

    }

    return (
        <div id="add_url">
            <p>Podporované servery jsou:</p>
            <ul className="nice-list">
                <li><a href="https://pisnicky-akordy.cz" rel="noopener noreferrer" target="_blank" className="nice-link">pisnicky-akordy.cz</a></li>
                <li><a href="https://ukutabs.com" rel="noopener noreferrer" target="_blank" className="nice-link">ukutabs.com</a></li>
            </ul>

            <label>Zadejte URL: </label>
            <input type="text" className={`qsearch form-control ${valid ? '' : 'invalid'}`} id="typeahead" placeholder="Url" value={url} onChange={(e) => setUrlAndValidate(e.target.value)} />
            <button id="search" disabled={!valid} onClick={(e) => parseUrl(e)}>Načíst</button>

        </div>
    );
}


const mapStateToProps = (state, props) => ({
    props: props,
});

export default connect(mapStateToProps, { parseSong })(AddSongUsingUrl);
