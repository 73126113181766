import React, { useState } from 'react';
import { upVote, downVote } from "../redux/actions";
import { connect } from "react-redux";
import { localStorageGet } from "../localStorage";

const Voting = ({ song, user, votes_up, votes_down, upVote, downVote }) => {

    const [voted, setVoted] = useState(localStorageGet('voted_' + song.id));

    const vote = type => {

        if (user && user.id) {
            if (type > 0) {
                if (!voted) {
                    upVote(song)
                    setVoted(true);
                }

            } else {
                if (!voted) {
                    downVote(song);
                    setVoted(true);
                }
            }
        } else {
            alert('Nejprve se prosím přihlašte.')
        }

    };


    return (
        <div className="vote">
            <button
                className={`up ${voted ? 'voted' : ''}`}
                onClick={e => vote(1)}
            >
                {votes_up}
            </button>
            <button
                className={`down ${voted ? 'voted' : ''}`}
                onClick={e => vote(-1)}
            >
                {votes_down}
            </button>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    song: props.song,
    user: props.user,
    votes_up: props.song.votes_up,
    votes_down: props.song.votes_down,
});

export default connect(mapStateToProps, { upVote, downVote })(Voting);