import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { searchSongs } from "../redux/actions"
//import { NavLink } from 'react-router-dom';
import useFilterLocation from "../hooks/useFilterLocation";
import { getSearchByRouteSlug } from "../redux/selectors"

/* Custom components */
import TagsFilter from "./TagsFilter"

const Search = ({ props, searchText, searchSongs, baseUrl }) => {

    const [search, setSearch] = useState(searchText)
    const { basePath, allPath } = useFilterLocation();

    console.log(allPath);
    console.log(basePath);

    useEffect(() => {
        setSearch(searchText)
        searchSongs(searchText)
        if (searchText) {
        }
    }, [searchText])

    const doSearch = text => {
        setSearch(text)
        if (text.length) {
            props.history.push(basePath + 'hledat/' + text);
        } else {
            props.history.push(allPath);
        }
    }

    const searchSubmit = () => {
        searchSongs(search)
    }

    return (
        <div id="search_panel">
            <div id="search_wrap">
                <label>Vyhledávání: </label>
                <input type="text" placeholder="Vyhledat" value={search} onChange={e => doSearch(e.target.value)} />
                <button id="search" onClick={e => searchSubmit()}></button>
            </div>
            <div className="tags_wrap">
                <TagsFilter {...props} basePath={basePath} allPath={allPath} />
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    props: props,
    //searchText: state.songs.searchText, 
    searchText: getSearchByRouteSlug(state, props),
})

export default connect(mapStateToProps, { searchSongs })(Search)
