import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

/* Custom components */
import TopRow from "./components/user/TopRow"

import { Redirect, NavLink } from "react-router-dom"

const NotFound = ({ props }) => {
    /// deconstruct the state


    useEffect(() => { }, [])


    return (
        <React.Fragment>
            <TopRow back="/pisnicky" backTitle="Zpět na seznam" />
            <div className="not-found">
                <div className="title">
                    <h1>Stránka nenalezena</h1>
                </div>


                <NavLink to="/pisnicky" className="button big b">
                    Zpět na seznam písniček
                </NavLink>

            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state, props) => ({
    props: props
})

export default connect(mapStateToProps, {})(NotFound)
