import { useState } from 'react';
//import useResize from "../hooks/useResize"

const useChordRow = (editMode) => {
  const [activeRow, setActiveRow] = useState(initialRow);
  const [chordsBar, setChordsBar] = useState(initialBar);
  const [defaultChord, setDefaultChord] = useState(initialChord);

  /**
   * Click on empty row or existing chord
  */
  const onClick = (e, chord = null, row = 0, isDragging) => {
    e.stopPropagation();
    //Click outside chords row or click in a view mode
    if (!editMode || isDragging || (!chord && !e.target.id.includes("chordsRow-"))) return;

    const el = document.getElementById("songbody");
    const height = el.clientHeight;
    const leftPad = el.getBoundingClientRect().left + 7.5;
    let id = 0, X = 0, Y = 0, chordBarX = 0, width = 0, activeRow = {}, chordsBar = {}, defaultChord = {};
    let selectedChord = initialChord
    if (chord) {
      chordBarX = calcChordBarProps(chord.position)
      activeRow = { id: row, Y: height - e.target.parentNode.offsetTop, X: chord.position, chordBarX };
      chordsBar = { ...chordsBar, isVisible: true, showDelete: true };
      selectedChord = chord;
    } else {
      id = e.target.id ? e.target.id.split('-')[1] : e.target.parentNode.id.split('-')[1];
      X = e.clientX ? e.clientX : e.touches === undefined ? 0 : e.touches[0].clientX;
      Y = e.target.offsetTop;
      width = e.target.offsetWidth;
      console.log(X, leftPad, X - leftPad);
      chordBarX = calcChordBarProps(X);
      activeRow = {
        id: parseInt(id, 10), Y: height - Y, X: X - leftPad, chordBarX
      }
      chordsBar = { ...chordsBar, isVisible: true };
    }
    setActiveRow(activeRow);
    setDefaultChord(selectedChord);
    setChordsBar(chordsBar);
  }

  const resetChordsBar = () => {
    setChordsBar(initialBar);
    setDefaultChord(initialChord);
  }

  const hideChordBar = () => {
    setChordsBar({ ...chordsBar, isVisible: false });
  }

  return {
    activeRow,
    chordsBar,
    defaultChord,
    onClick,
    resetChordsBar,
    hideChordBar
  }
}
export default useChordRow;

const initialRow = {
  id: 0,
  X: 0,
  Y: 0,
  chordBarX: 0
}

const initialBar = {
  isVisible: false,
  showDelete: false
}

const initialChord = {
  chord: null,
  chordType: null
}

const calcChordBarProps = (x) => {
  return 0;
  /*
  let coef = x * (document.body.clientWidth - 530) / document.body.clientWidth; // 0 <--> document.body.clientWidth-350
  //    console.warn(coef);
  if (coef < document.body.clientWidth / 2) {
      x = coef - 30;
  } else {
      x = coef + 30;
  }
  return x;
  */
}