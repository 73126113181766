import React from 'react';
import { accords } from "../data/chords";

const ShowAccord = ({ chordName, instrument, small = false }) => {

  if (!accords[instrument][chordName]) {
    return '';
  }

  return (
    <span className={`accord ${instrument} ${small === true ? 'sm' : ''}`}>
      <span className="head" title={chordName}>
        {
          accords[instrument][chordName].map((strings, row) => (
            strings.map((isPressed, string) => (
              <span key={`finger-${row}-${string}`} className={`finger ${isPressed ? 'pressed' : ''}  r${row + 1} s${string + 1}`}></span>
            ))
          ))
        }
      </span>
    </span>
  );
}

export default ShowAccord;