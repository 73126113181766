import { useLocation } from "react-router-dom"

const useFilterLocation = () => {

    const location = useLocation()
    var basePath, allPath;

    const parts = location.pathname.split('/');


    if (parts[1] === 'muj-zpevnik') {
        basePath = allPath = parts[1] + '/';
    } else if (parts[1] === 'pisnicky' || parts[1] === 'od-pismene' || parts[1] === 'hledat' || parts[1] === 'kategorie') {
        basePath = '';
        allPath = 'pisnicky';
    } else {
        basePath = allPath = parts[1] + '/';
    }

    return {
        basePath: '/' + basePath,
        allPath: '/' + allPath
    };
}

export default useFilterLocation;