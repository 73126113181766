import { createSelector } from 'reselect';
// using reselect for memoized selectors

// selector
export const getSongsFromState = (state) => state.songs.songsListFiltered;
export const getAllSongsFromState = (state) => state.songs.songsList;
export const getHistorySongsIdsFromState = (state) => [state.history.lastVisited, state.songs.songsList];
export const getAllTagsFromState = (state) => state.tags.tagsList;
export const getInstrumentFromState = (state) => state.songs.instrument;
export const getUserLoggedBooleanFromState = (state) => state.user.loggedIn;
export const getUserFromState = (state) => state.user.user;
export const getUserPlaylistFromState = (state) => state.user.playlist;
export const getPublicUserPlaylistFromState = (state) => state.user.publicPlaylist;

function getSongsByUser(state, props) {

    const playlist = getUserPlaylistFromState(state);
    const allSongs = getSongsFromState(state)// getAllSongsFromState(state);
    var songs = [];

    allSongs.forEach(song => {
        if (playlist.indexOf(song.id) >= 0) {
            songs.push(song)
        }
    });

    return songs;

}

function getSongsByPublicUser(state, props) {

    const playlist = getPublicUserPlaylistFromState(state);
    const allSongs = getSongsFromState(state)// getAllSongsFromState(state);
    var songs = [];

    allSongs.forEach(song => {
        if (playlist.indexOf(song.id) >= 0) {
            songs.push(song)
        }
    });

    return songs;

}




function getSongByIdFromRoute(state, props) {
    var id = Number(props.match.params.id);

    if (!id) { //new-song
        id = 0;
    }

    const songs = getAllSongsFromState(state);
    return getSongById(songs, id);

}

function getSongBySlugFromRoute(state, props) {
    var slug = props.match.params.slug;
    const songs = getAllSongsFromState(state);
    if (!slug) { //new-song
        return getSongById(songs, 0);
    }
    return getSongBySlug(songs, slug);

}

function getTagBySlugFromRoute(state, props) {
    var slug = props.match.params.slug;
    return getTagBySlug(getAllTagsFromState(state), slug);
}

function getLetterBySlugFromRoute(state, props) {
    var letter = props.match.params.letter;
    return letter ? letter : '';
}

function getSearchBySlugFromRoute(state, props) {
    var search = props.match.params.search;
    return search ? search : '';
}


export const getSongIndexById = (songs, id) => {
    var index;
    songs.some(function (obj, i) {
        if (obj.id === id) return (index = i);
    });

    return index;
}

export const getSongById = (songs, id) => {

    let song = false;

    var found = songs.filter(obj => {
        return obj.id === id
    })

    if (found.length) {
        song = found[0];
    }

    return song;
}

export const getSongBySlug = (songs, slug) => {

    let song = false;

    var found = songs.filter(obj => {
        return obj.slug === slug
    })

    if (found.length) {
        song = found[0];
    }

    return song;
}


export const getTagBySlug = (tags, slug) => {

    let tag = false;

    var found = tags.filter(obj => {
        return obj.slug === slug
    })

    if (found.length) {
        tag = found[0].id;
    }

    return tag;
}

function groupByParent(songs) {
    var songGroups = {};
    songs.forEach(song => {
        if (song.original_id) {
            console.log(song);

            if (typeof songGroups[song.original_id] === 'undefined') {
                songGroups[song.original_id] = [];
            }
            songGroups[song.original_id].push(getSongById(songs, song.id))
        }


    });
    return songGroups;
}



function groupByLetter(songs) {

    songs.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });

    let data = songs.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];

        if (typeof group === 'undefined' || e.id === 0) {
            return r;
        }

        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        group = group.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if (group === 'c' || group === 'C') {
            let letter2 = e.name[1];
            if (letter2 === 'h' || letter2 === 'H') {
                group += letter2;
            }
        } else if (!isNaN(group)) { // is number
            group = '0-9';
        }

        group = group.toUpperCase();

        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] }
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator 
        return r;

    }, {})

    // since data at this point is an object, to get array of values
    // we use Object.values method
    return Object.values(data)
}

function getSongsFromIds(data) {
    const [songIds, songs] = data;
    if (!songIds || !songs) {
        return [];
    }
    return songIds.map(s => getSongById(songs, s));
}



export const removeClonedSongs = (songs) => {
    return songs.filter(s => {
        return s.original_id === 0
    })
}

// reselect function 
export const getPublicUserSongsList = createSelector(
    [getSongsByPublicUser],
    (songs) => groupByLetter(songs)
)

export const getPublicUserSongsCount = createSelector(
    [getSongsByPublicUser],
    (songs) => songs.length
)

export const getUserSongsList = createSelector(
    [getSongsByUser],
    (songs) => groupByLetter(songs)
)

export const getUserSongsCount = createSelector(
    [getSongsByUser],
    (songs) => songs.length
)

export const getSongsListGrouped = createSelector(
    [getSongsFromState],
    (songs) => groupByLetter(songs)
)

export const getSongsCount = createSelector(
    [getSongsFromState],
    (songs) => songs.length
)



// songs grouped by parent id / copied
export const getSongGroups = createSelector(
    [getAllSongsFromState],
    (songGroups) => groupByParent(songGroups)
)



export const getAllSongsListGrouped = createSelector(
    [getAllSongsFromState],
    (songs) => groupByLetter(songs)
)

export const getSongByRouteId = createSelector(
    [getSongByIdFromRoute],
    (song) => (song)
)

export const getSongByRouteSlug = createSelector(
    [getSongBySlugFromRoute],
    (song) => (song)
)

export const getLetterByRouteSlug = createSelector(
    [getLetterBySlugFromRoute],
    (letter) => letter
)

export const getSearchByRouteSlug = createSelector(
    [getSearchBySlugFromRoute],
    (search) => search
)

export const getTagByRouteSlug = createSelector(
    [getTagBySlugFromRoute],
    (tag) => tag
)

export const getTagsFromState = createSelector(
    [getAllTagsFromState],
    (tags) => tags
)

export const getSongsHistory = createSelector(
    [getHistorySongsIdsFromState],
    (songs) => getSongsFromIds(songs)
)

export const getInstrument = createSelector(
    [getInstrumentFromState],
    (instrument) => instrument
)

/* user */
export const isUserLoggedIn = createSelector(
    [getUserLoggedBooleanFromState],
    (loggedIn) => loggedIn
)

export const getUser = createSelector(
    [getUserFromState],
    (user) => user
)

export const getUserPlaylist = createSelector(
    [getUserPlaylistFromState],
    (playlist) => playlist
)

