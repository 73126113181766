import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from "./reducers";
/* Import state from LocalStorage */
import { loadState } from '../localStorage';

const persistedState = loadState();
const middleWare = [thunk];

export default createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middleWare))
);
