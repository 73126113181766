import React from "react"
//import { NavLink } from 'react-router-dom';
import { connect } from "react-redux"
import { getSongsHistory } from "./redux/selectors"
import SongRow from "./components/SongRow"
import TopRow from "./components/user/TopRow"

const History = ({ songs }) => {
    /// deconstruct the state

    return (
        <React.Fragment>
            <TopRow back="/pisnicky" backTitle="Zpět na seznam" />
            <div className="modul">
                <div id="com_lyrics" className="">
                    <div className="title">
                        <h1>Historie</h1>
                    </div>

                    <div className="clearfix row-fluid">
                        <div className="lyrics_main">
                            <div className="tab-content" id="IntTabContent">
                                <div className="tab-pane">
                                    <ul className="itemlist">
                                        {songs.map(song => (
                                            <SongRow key={`song-${song.id}`} song={song} />
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state, props) => ({
    songs: getSongsHistory(state, props),
})

export default connect(mapStateToProps)(History)
