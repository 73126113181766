export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return undefined
    }

    const state = JSON.parse(serializedState)

    // check version
    if (process.env.REACT_APP_VERSION !== state.app.version) {
      localStorage.removeItem("state")
      return undefined
    }
    return state
  } catch (error) {
    return undefined
  }
}

export const saveState = state => {
  try {
    state.app.version = process.env.REACT_APP_VERSION
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (error) {
    console.log(error)
  }
}

/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
export const localStorageGet = key => {
  var item = localStorage.getItem(key)

  if (!item) return

  if (item[0] === "{" || item[0] === "[") return JSON.parse(item)

  return item
}

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
export const localStorageSave = (key, value) => {
  if (value === undefined) {
    console.error("Can't store undefinded value")
  }

  if (typeof value === "object" || typeof value === "array") {
    value = JSON.stringify(value)
  }

  if (typeof value !== "string") {
    console.error("Can't store unrecognized format value")
  }

  localStorage.setItem(key, value)
}
