import { LOAD_TAGS } from '../actionTypes';

const initialState = {
    tagsList: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_TAGS: {
            return {
                ...state, tagsList: action.payload
            };
        }
        default:
            return state;
    }
}
