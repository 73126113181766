import React, { useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { useDispatch, connect } from "react-redux";
import { getAllSongsFromState, getTagsFromState, isUserLoggedIn } from "./redux/selectors"
import throttle from "lodash/throttle"
// toasts
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/* Redux store */
import store from "./redux/store"
import Loading from "./components/Loading"
import { getSongs, getTags, validateUser, logoutUser } from "./redux/actions"

/* Local storage */
import { saveState } from "./localStorage"

import Homepage from "./Homepage"
import UserProfile from "./UserProfile"
import UserPublicProfile from "./UserPublicProfile"
import Song from "./Song"
import Songs from "./Songs"
import History from "./History"
import NotFound from "./NotFound"
import Footer from "./components/Footer"

/* Update local storage each time redux store is updated (but not more often as once per 1000ms)*/
store.subscribe(
  throttle(() => {
    saveState(store.getState()) // commented for dev purposes
  }, 1000)
)

function App({ songs, tags, loggedIn, getSongs, getTags, validateUser, logoutUser }) {
  const [isLoaded, setIsLoaded] = useState(false)

  const dispatch = useDispatch();

  const validate = async () => {
    // login
    const act = localStorage.getItem("access_token");
    if (act || (!act && loggedIn)) {
      const valid = await validateUser();
      if (!valid) {
        dispatch({ type: "LOGOUT_USER", payload: true })
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrolling);

    (async () => {
      await validate();
      await getSongs()
      await getTags()

      setIsLoaded(true)
    })();


    //

    return () => {
      window.removeEventListener("scroll", scrolling)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (songs.length && tags.length) {
    }
  }, [songs, tags])

  const scrolling = () => {
    if (window.scrollY > 0) {
      document.body.classList.add("scrolling")
    } else {
      document.body.classList.remove("scrolling")
    }
  }

  return isLoaded ? (
    <div id="content" role="main">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={props => <Homepage {...props} songs={songs} />} />
          <Route exact path="/pisnicky" component={Songs} />
          <Route exact path="/historie" component={History} />
          <Route exact path="/pridat-pisnicku" component={Song} />
          <Route exact path="/muj-zpevnik" component={UserProfile} />
          <Route exact path="/muj-zpevnik/od-pismene/:letter" component={UserProfile} />
          <Route exact path="/muj-zpevnik/hledat/:search" component={UserProfile} />
          <Route exact path="/muj-zpevnik/kategorie/:slug" component={UserProfile} />
          <Route exact path="/:user" component={UserPublicProfile} />
          <Route exact path="/:user/od-pismene/:letter" component={UserPublicProfile} />
          <Route exact path="/:user/kategorie/:slug" component={UserPublicProfile} />
          <Route exact path="/:user/hledat/:search" component={UserPublicProfile} />
          <Route exact path="/pisnicka/:slug" component={Song} />
          <Route exact path="/kategorie/:slug" component={Songs} />
          <Route exact path="/od-pismene/:letter" component={Songs} />
          <Route exact path="/hledat/:search" component={Songs} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      <Footer songs={songs} />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable
      />
    </div>
  ) : (
    <Loading />
  )
}

const mapStateToProps = (state, props) => ({
  songs: getAllSongsFromState(state),
  tags: getTagsFromState(state),
  loggedIn: isUserLoggedIn(state),
})

export default connect(mapStateToProps, { getSongs, getTags, validateUser, logoutUser })(App)
