
export default {
    helpSearch: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="1347px" height="481px" viewBox="0 0 1347 481" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Group 14</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Home" transform="translate(-48.000000, -1659.000000)">
                <g id="Group-14" transform="translate(48.000000, 1659.000000)">
                    <g id="Group-5" transform="translate(94.000000, 209.000000)">
                        <text id="Jak-se-to-jmenuje" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="72" y="23">Jak se to jmenuje</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(29.000000, 43.000000) rotate(90.000000) translate(-29.000000, -43.000000) translate(2.000000, 14.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 58 53.5 53 49 47.5"></polyline>
                        </g>
                    </g>
                    <g id="Group-6" transform="translate(175.000000, 352.000000)">
                        <text id="Kdo-to-skládal" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0" y="59">Kdo to skládal</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(158.000000, 27.000000) rotate(-90.000000) translate(-158.000000, -27.000000) translate(131.000000, -2.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 58 53.5 53 49 47.5"></polyline>
                        </g>
                        <text id="Kdo-to-přidal" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="280" y="59">Kdo to přidal</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(431.000000, 27.000000) rotate(-90.000000) translate(-431.000000, -27.000000) translate(404.000000, -2.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 58 53.5 53 49 47.5"></polyline>
                        </g>
                    </g>
                    <g id="Group-6" transform="translate(721.000000, 352.000000)">
                        <text id="Když-se-mi-to-líbí" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="74" y="59">Když se mi to líbí</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(32.000000, 27.000000) scale(1, -1) rotate(90.000000) translate(-32.000000, -27.000000) translate(5.000000, -5.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,49 C6.76353751e-16,54.5228475 4.4771525,59 10,59 L47,59 L47,59" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 64 54 58.7619048 49 53"></polyline>
                        </g>
                    </g>
                    <g id="Group-6" transform="translate(29.000000, 356.000000)">
                        <text id="Když-je-tam-tohle,-t" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="74" y="116">Když je tam tohle, tak můžu rozbalit varianty písniček</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(32.000000, 56.000000) scale(1, -1) rotate(90.000000) translate(-32.000000, -56.000000) translate(-24.000000, 24.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0.5,0.5 L0.5,49.5 C0.5,55.0228475 4.9771525,59.5 10.5,59.5 L104.5,59.5 L104.5,59.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="107 64 112 58.7619048 107 53"></polyline>
                        </g>
                    </g>
                    <g id="Group-9" transform="translate(1134.000000, 352.000000)">
                        <text id="To-chci-pak-hrát" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.121058824" y="59">To chci pak hrát</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(163.000000, 27.000000) rotate(-90.000000) translate(-163.000000, -27.000000) translate(136.000000, -2.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 58 53.5 53 49 47.5"></polyline>
                        </g>
                    </g>
                    <g id="Group" transform="translate(1.000000, 291.000000)">
                        <g id="Song">
                            <g id="Cards/Body" opacity="0.8" fill="#FFFFFF">
                                <rect id="Color" x="0" y="0" width="1284" height="54" rx="4"></rect>
                            </g>
                            <text id="Abych-tu-žil" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="20" letter-spacing="0.1" fill="#000000">
                                <tspan x="61" y="30">Abych tu žil</tspan>
                            </text>
                            <text id="Michal-Tučný" font-family="Roboto-Regular, Roboto" font-size="14" font-weight="normal" line-spacing="20" letter-spacing="0.1" fill="#000000">
                                <tspan x="316" y="30">Michal Tučný</tspan>
                            </text>
                            <text id="@bob_code8" font-family="Roboto-Regular, Roboto" font-size="14" font-weight="normal" line-spacing="20" letter-spacing="0.1" fill="#000000">
                                <tspan x="572" y="30">@bob_code8</tspan>
                            </text>
                            <text id="2567" font-family="Roboto-Regular, Roboto" font-size="14" font-weight="normal" line-spacing="20" letter-spacing="0.1" fill="#000000">
                                <tspan x="745" y="30">2567</tspan>
                            </text>
                            <g id="Chips/Medium/Basic/Text" transform="translate(994.000000, 11.000000)">
                                <rect id="↳Select-Variant" x="0" y="0" width="70" height="32" rx="16"></rect>
                                <text id="Medium" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="18.59" letter-spacing="0.073125" fill="#000000" fill-opacity="0.87">
                                    <tspan x="12" y="19"># české</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(1072.000000, 8.500000)">
                                <rect id="↳Select-Variant" fill="#FECDD2" fill-rule="nonzero" x="0" y="0" width="87" height="37" rx="16"></rect>
                                <text id="Medium" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="18.59" letter-spacing="0.073125" fill="#000000" fill-opacity="0.87">
                                    <tspan x="12" y="21.578125"># táborové</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(1167.000000, 11.000000)">
                                <rect id="↳Select-Variant" x="0" y="0" width="96" height="32" rx="16"></rect>
                                <text id="Medium" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="18.59" letter-spacing="0.073125" fill="#000000" fill-opacity="0.87">
                                    <tspan x="12" y="19"># zahraniční</tspan>
                                </text>
                            </g>
                        </g>
                        <g id="Button-Text-+-★/Slim/Contained/Default" transform="translate(1291.000000, 0.000000)" fill="#FECDD2">
                            <rect id="↳BG" fill-opacity="0.2" x="0" y="0" width="54" height="54" rx="13"></rect>
                            <g id="icons/ic/outline-keyboard_arrow_left-24px" transform="translate(18.000000, 19.000000)">
                                <path d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,0.818181818 C4.48130659,0.818181818 0.818181818,4.48130659 0.818181818,9 C0.818181818,13.5186934 4.48130659,17.1818182 9,17.1818182 C13.5186934,17.1818182 17.1818182,13.5186934 17.1818182,9 C17.1818182,4.48130659 13.5186934,0.818181818 9,0.818181818 Z M9.40909091,4.5 L9.40909091,8.59090909 L13.5,8.59090909 L13.5,9.40909091 L9.40909091,9.40909091 L9.40909091,13.5 L8.59090909,13.5 L8.59090909,9.40909091 L4.5,9.40909091 L4.5,8.59090909 L8.59090909,8.59090909 L8.59090909,4.5 L9.40909091,4.5 Z" id="Contact-Add"></path>
                            </g>
                        </g>
                        <g id="thumbs-up-solid" transform="translate(711.000000, 12.000000)" fill="#000000" fill-rule="nonzero">
                            <path d="M4.87499655,10.5 L1.1249992,10.5 C0.503671519,10.5 0,11.0036719 0,11.625 L0,22.875 C0,23.4963281 0.503671519,24 1.1249992,24 L4.87499655,24 C5.49632424,24 6,23.4963281 6,22.875 L6,11.625 C6,11.0036719 5.49632424,10.5 4.87499655,10.5 Z M2.99999788,22.125 C2.37867019,22.125 1.87499867,21.6213281 1.87499867,21 C1.87499867,20.3786719 2.37867019,19.875 2.99999788,19.875 C3.62132556,19.875 4.12499708,20.3786719 4.12499708,21 C4.12499708,21.6213281 3.62132556,22.125 2.99999788,22.125 Z M17.9999947,3.8180625 C17.9999947,5.8063125 16.7826444,6.9215625 16.440129,8.25 L21.2083913,8.25 C22.7738745,8.25 23.9926237,9.55059375 23.9999683,10.9733438 C24.0038737,11.8141875 23.6462177,12.7193906 23.0887337,13.2794531 L23.0835774,13.2846094 C23.5446396,14.3785313 23.4696865,15.9113438 22.6472652,17.0097188 C23.0541868,18.2235469 22.6440309,19.7145938 21.8793595,20.5139531 C22.0808281,21.3388594 21.984547,22.0409062 21.5911722,22.6060781 C20.6344542,23.9806406 18.2632371,24 16.2580666,24 L16.1247073,24 C13.8612558,23.9991563 12.008804,23.1750469 10.5203363,22.5128438 C9.77235246,22.1800781 8.79435316,21.7681406 8.05232243,21.7545 C7.74576015,21.748875 7.4999947,21.4987031 7.4999947,21.1920938 L7.4999947,11.171625 C7.4999947,11.021625 7.5600884,10.8776719 7.66677583,10.7722031 C9.52368077,8.93732813 10.322149,6.9946875 11.8441322,5.470125 C12.5380693,4.774875 12.7904441,3.7246875 13.0344283,2.70909375 C13.2428813,1.84185937 13.6789122,0 14.6249897,0 C15.7499889,0 17.9999947,0.375 17.9999947,3.8180625 Z" id="Shape"></path>
                        </g>
                    </g>
                    <g id="chevron-square-down-light" transform="translate(21.000000, 307.000000)" fill="#000000" fill-rule="nonzero">
                        <path d="M20.5357143,0 L2.46428571,0 C1.10379464,0 0,1.10379464 0,2.46428571 L0,20.5357143 C0,21.8962054 1.10379464,23 2.46428571,23 L20.5357143,23 C21.8962054,23 23,21.8962054 23,20.5357143 L23,2.46428571 C23,1.10379464 21.8962054,0 20.5357143,0 Z M21.3571429,20.5357143 C21.3571429,20.9875 20.9875,21.3571429 20.5357143,21.3571429 L2.46428571,21.3571429 C2.0125,21.3571429 1.64285714,20.9875 1.64285714,20.5357143 L1.64285714,2.46428571 C1.64285714,2.0125 2.0125,1.64285714 2.46428571,1.64285714 L20.5357143,1.64285714 C20.9875,1.64285714 21.3571429,2.0125 21.3571429,2.46428571 L21.3571429,20.5357143 Z M11.0636161,15.5866071 L5.15959821,9.67745536 C4.91830357,9.43616071 4.91830357,9.04598214 5.15959821,8.8046875 L5.52410714,8.44017857 C5.76540179,8.19888393 6.15558036,8.19888393 6.396875,8.44017857 L11.5,13.5535714 L16.6082589,8.4453125 C16.8495536,8.20401786 17.2397321,8.20401786 17.4810268,8.4453125 L17.8455357,8.80982143 C18.0868304,9.05111607 18.0868304,9.44129464 17.8455357,9.68258929 L11.9415179,15.5917411 C11.6950893,15.8227679 11.3049107,15.8227679 11.0636161,15.5866071 L11.0636161,15.5866071 Z" id="Shape"></path>
                    </g>
                    <g id="Group-12" transform="translate(29.000000, 0.000000)">
                        <text id="Zobrazí-to-všechny-p" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="72" y="23">Zobrazí to všechny písničky</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(29.000000, 52.000000) rotate(90.000000) translate(-29.000000, -52.000000) translate(-7.000000, 23.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,43 C6.76353751e-16,48.5228475 4.4771525,53 10,53 L65,53 L65,53" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="67 58 72 52.7619048 67 47"></polyline>
                        </g>
                    </g>
                    <g id="Group-7" transform="translate(366.000000, 155.000000)">
                        <text id="Zobrazí-to-písničky" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.982941176" y="59">Zobrazí to písničky začínající na D</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(298.000000, 27.000000) rotate(-90.000000) translate(-298.000000, -27.000000) translate(271.000000, -2.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="49 58 53.5 53 49 47.5"></polyline>
                        </g>
                    </g>
                    <g id="Light" transform="translate(0.000000, 52.000000)">
                        <g id="Abeceda" transform="translate(0.000000, 53.000000)">
                            <g id="Button-Text/Default/Contained/Default">
                                <rect id="↳Color" fill="#FECDD2" fill-rule="nonzero" x="0" y="0" width="66.0982143" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3" fill="#1E2432">
                                    <tspan x="19.5490523" y="24">VŠE</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(80.118915, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">A</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(133.197696, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">B</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(185.274990, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">C</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(302.448903, 0.000000)">
                                <rect id="↳Color" fill="#4B4652" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3" fill="#FECDD2">
                                    <tspan x="18.5349312" y="24">D</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(238.353771, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="58.0863095" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="19.0431066" y="24">CH</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(355.527684, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">E</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(407.604979, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="45.0669643" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5334448" y="24">F</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(457.679301, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="48.0714286" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5356744" y="24">H</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(511.759568, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="42.0625" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5312151" y="24">I</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(558.829430, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">J</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(610.906725, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">K</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(663.985506, 0.000000)">
                                <rect id="↳Color" fill="#FECDD2" fill-rule="nonzero" x="0" y="0" width="45.0669643" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3" fill="#1E2432">
                                    <tspan x="18.5334448" y="24">L</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(714.059828, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="51.0758929" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="19.0379041" y="24">M</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(771.144555, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="48.0714286" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5356744" y="24">N</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(825.224822, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">O</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(878.303603, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">P</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(930.380898, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">R</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1000.484948, 6.000000)" fill="#FFFFFF" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" letter-spacing="0.3" line-spacing="24">
                                <text id="Button-Text">
                                    <tspan x="0.507432172" y="13">S</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(983.459679, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">S</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1034.535487, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">T</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1086.612781, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">U</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1138.690076, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="47.0699405" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.5349312" y="24">V</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1191.768857, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="51.0758929" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="19.0379041" y="24">W</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1247.852097, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">Y</tspan>
                                </text>
                            </g>
                            <g id="Button-Text/Default/Contained/Default" transform="translate(1299.929392, 0.000000)" fill="#FECDD2">
                                <rect id="↳Color" fill-opacity="0.2" fill-rule="nonzero" x="0" y="0" width="46.0684524" height="36" rx="10"></rect>
                                <text id="Button-Text" font-family="Roboto-Medium, Roboto" font-size="14" font-weight="400" line-spacing="14" letter-spacing="0.3">
                                    <tspan x="18.534188" y="24">Z</tspan>
                                </text>
                            </g>
                        </g>
                        <g id="Tagy" transform="translate(760.000000, 4.000000)">
                            <g id="Chips/Medium/Basic/Text" fill="#FECDD2">
                                <rect id="↳Select-Variant" fill-opacity="0.2" x="0" y="0" width="74.1264957" height="37" rx="16"></rect>
                                <text id="#-české" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125">
                                    <tspan x="10.8490222" y="24.168125"># české</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(82.140171, 0.000000)" fill="#FECDD2">
                                <rect id="↳Select-Variant" fill-opacity="0.2" x="0" y="0" width="88.1504274" height="37" rx="16"></rect>
                                <text id="#-táborové" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125">
                                    <tspan x="9.91165092" y="24.168125"># táborové</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(178.304274, 0.000000)" fill="#FECDD2">
                                <rect id="↳Select-Variant" fill-opacity="0.2" x="0" y="0" width="98.1675214" height="37" rx="16"></rect>
                                <text id="#-zahraniční" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125">
                                    <tspan x="9.18037205" y="24.168125"># zahraniční</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(284.485470, 0.000000)">
                                <rect id="↳Select-Variant" fill="#FECDD2" fill-rule="nonzero" x="0" y="0" width="87.1487179" height="37" rx="16"></rect>
                                <text id="Medium" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125" fill="#000000" fill-opacity="0.87">
                                    <tspan x="12.0205128" y="24.168125"># táborové</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(380.649573, 0.000000)" fill="#FECDD2">
                                <rect id="↳Select-Variant" fill-opacity="0.2" x="0" y="0" width="126.215385" height="37" rx="16"></rect>
                                <text id="#-melancholické" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125">
                                    <tspan x="13.8952717" y="24.168125"># melancholické</tspan>
                                </text>
                            </g>
                            <g id="Chips/Medium/Basic/Text" transform="translate(513.876923, 0.000000)" fill="#FECDD2">
                                <rect id="↳Select-Variant" fill-opacity="0.2" x="0" y="0" width="72.1230769" height="37" rx="16"></rect>
                                <text id="#-veselé" font-family="Roboto-Regular, Roboto" font-size="13" font-weight="normal" line-spacing="13" letter-spacing="0.073125">
                                    <tspan x="8.9507582" y="24.168125"># veselé</tspan>
                                </text>
                            </g>
                        </g>
                        <g id="Vyhledavani" transform="translate(83.000000, 0.000000)">
                            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="425" height="37" rx="10"></rect>
                            <text id="Vyhledat-písničku" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" line-spacing="19" letter-spacing="0.3" fill="#1E2432">
                                <tspan x="17" y="19">Vyhledat písničku</tspan>
                            </text>
                            <path d="M405.151744,17.3087874 L404.379989,17.3087874 L404.111721,17.5750958 C405.059405,18.6801784 405.635792,20.1128009 405.635792,21.6824653 C405.635792,25.1716883 402.807318,28 399.317896,28 C395.828473,28 393,25.1716883 393,21.6824653 C393,18.1932422 395.828473,15.3649305 399.317896,15.3649305 C400.88765,15.3649305 402.319383,15.9403122 403.424528,16.8869704 L403.692796,16.6206621 L403.692796,15.8508947 L408.550772,11 L410,12.4491453 L405.151744,17.3087874 L405.151744,17.3087874 Z M399.317896,17.3087874 C396.901544,17.3087874 394.943968,19.2662512 394.943968,21.6824653 C394.943968,24.0977074 396.901544,26.0561432 399.317896,26.0561432 C401.733276,26.0561432 403.691824,24.0977074 403.691824,21.6824653 C403.691824,19.2662512 401.733276,17.3087874 399.317896,17.3087874 L399.317896,17.3087874 Z" id="Shape" fill="#1E2432" transform="translate(401.500000, 19.500000) scale(1, -1) translate(-401.500000, -19.500000) "></path>
                        </g>
                    </g>
                    <g id="Group-10" transform="translate(808.000000, 0.000000)">
                        <text id="Ukáže-to-všechno-v-k" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.362352941" y="23">Ukáže to všechno v kategorii</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(255.000000, 30.000000) scale(-1, 1) rotate(90.000000) translate(-255.000000, -30.000000) translate(243.000000, 1.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M5.68434189e-14,0 L5.68434189e-14,43 C5.75197726e-14,48.5228475 4.4771525,53 10,53 L17,53 L17,53" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="19 58 24 52.7619048 19 47"></polyline>
                        </g>
                    </g>
                    <g id="Group-11" transform="translate(450.000000, 0.000000)">
                        <text id="Hledá-to-jako-na-Gůg" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="79" y="23">Hledá to jako na Gůglu</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(32.500000, 30.500000) scale(-1, -1) rotate(-90.000000) translate(-32.500000, -30.500000) translate(20.000000, -2.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0.5,0.5 L0.5,49.5 C0.5,55.0228475 4.9771525,59.5 10.5,59.5 L18.5,59.5 L18.5,59.5" id="Path" stroke-dasharray="4,7"></path>
                            <polyline id="Path-2" stroke-linejoin="round" points="20 65 25 59.7619048 20 54"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>`,
    helpEdit: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="1018px" height="579px" viewBox="0 0 1018 579" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Group 13</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Home" transform="translate(-74.000000, -2400.000000)">
                <g id="Group-13" transform="translate(74.000000, 2400.000000)">
                    <g id="Group-8" transform="translate(794.000000, 0.000000)">
                        <text id="Přidá-to-nový-řádek" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.826823529" y="23">Přidá to  nový řádek</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(195.500000, 87.500000) scale(-1, 1) rotate(90.000000) translate(-195.500000, -87.500000) translate(128.000000, 61.000000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke-dasharray="4,7"></path>
                            <path d="M47,32.5 L47,42.5 C47,48.0228475 51.4771525,52.5 57,52.5 L129,52.5 L129,52.5" id="Path" stroke-dasharray="4,7" transform="translate(88.000000, 42.500000) scale(-1, 1) translate(-88.000000, -42.500000) "></path>
                            <polyline id="Path-2" stroke-linejoin="round" transform="translate(129.250000, 25.750000) rotate(-90.000000) translate(-129.250000, -25.750000) " points="127 31 131.5 26 127 20.5"></polyline>
                        </g>
                    </g>
                    <g id="Group-8" transform="translate(833.000000, 178.000000)">
                        <text id="Smaže-to-řádek" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.0571764706" y="393">Smaže to řádek</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(157.000000, 195.500000) rotate(-90.000000) translate(-157.000000, -195.500000) translate(-38.500000, 168.500000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <path d="M0.5,0.5 L0.5,43.5 C0.5,49.0228475 4.9771525,53.5 10.5,53.5 L47.5,53.5 L47.5,53.5" id="Path" stroke-dasharray="4,7"></path>
                            <path d="M47.5,33.5 L47.5,43.5 C47.5,49.0228475 51.9771525,53.5 57.5,53.5 L384.5,53.5 L384.5,53.5" id="Path" stroke-dasharray="4,7" transform="translate(216.000000, 43.500000) scale(-1, 1) translate(-216.000000, -43.500000) "></path>
                            <polyline id="Path-2" stroke-linejoin="round" transform="translate(385.500000, 26.500000) rotate(-90.000000) translate(-385.500000, -26.500000) " points="383 32 388 26.7619048 383 21"></polyline>
                        </g>
                    </g>
                    <rect id="Rectangle" stroke="#979797" fill="#D8D8D8" x="315.5" y="62.5" width="660" height="455" rx="10"></rect>
                    <g id="Group-2" transform="translate(329.000000, 165.000000)">
                        <rect id="Rectangle" stroke="#1E2432" fill="#C0C3C8" x="0.5" y="0.5" width="632" height="35" rx="10"></rect>
                        <rect id="Rectangle" fill-opacity="0.0563529488" fill="#1E2432" x="0" y="69" width="633" height="36" rx="10"></rect>
                        <rect id="Rectangle" fill-opacity="0.0563529488" fill="#1E2432" x="0" y="139" width="633" height="36" rx="10"></rect>
                        <rect id="Rectangle" fill-opacity="0.0563529488" fill="#1E2432" x="0" y="210" width="633" height="36" rx="10"></rect>
                    </g>
                    <text id="1.-Kapsy-mám,-abych" font-family="Roboto-Regular, Roboto" font-size="25" font-weight="normal" line-spacing="23" letter-spacing="0.140625" fill="#000000" fill-opacity="0.87">
                        <tspan x="390.5" y="194">1. Kapsy mám, abych v nich měl díru </tspan>
                        <tspan x="408.92041" y="223.403809"></tspan>
                        <tspan x="408.92041" y="246.403809"></tspan>
                        <tspan x="390.5" y="263">boty mám a na nich letní prach. </tspan>
                        <tspan x="408.92041" y="292.403809"></tspan>
                        <tspan x="408.92041" y="315.403809"></tspan>
                        <tspan x="390.5" y="332">Pod širou oblohou, jsem se sám usadil, </tspan>
                        <tspan x="408.92041" y="361.403809"></tspan>
                        <tspan x="408.92041" y="384.403809"></tspan>
                        <tspan x="390.5" y="401" line-spacing="27">že mám chuť v hlave si srovnat, pro co jsem žil. </tspan>
                    </text>
                    <rect id="Rectangle" fill="#151923" x="801" y="170" width="1" height="25"></rect>
                    <g id="akord" transform="translate(491.000000, 276.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.27140625" y="18">H</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(579.000000, 276.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="36" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="8.81746228" y="18">H7</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(673.000000, 348.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="36" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="6.95027478" y="18">Am</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(730.000000, 348.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="36" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.27449353" y="18">D7</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(669.000000, 276.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="36" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="7.56355603" y="18">Em</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(457.000000, 137.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.5096875" y="18">G</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(560.000000, 137.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.73234375" y="18">C</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(710.000000, 206.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.7284375" y="18">D</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(760.000000, 276.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.73234375" y="18">C</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(730.000000, 137.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.5096875" y="18">G</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(457.000000, 348.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.5096875" y="18">G</tspan>
                        </text>
                    </g>
                    <g id="akord" transform="translate(846.000000, 348.500000)">
                        <rect id="Rectangle" stroke-opacity="0.501195948" stroke="#1E2432" fill-opacity="0.4" fill="#FECDD2" x="0.5" y="1" width="28" height="23" rx="10"></rect>
                        <text id="H" font-family="Roboto-Medium, Roboto" font-size="16" font-weight="400" line-spacing="20" letter-spacing="0.09" fill="#1E2432">
                            <tspan x="9.5096875" y="18">G</tspan>
                        </text>
                    </g>
                    <g id="pridat" transform="translate(935.000000, 140.000000)">
                        <circle id="Oval" fill="#443E48" cx="10" cy="10" r="10"></circle>
                        <path d="M10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 Z M10,1.81818182 C5.48130659,1.81818182 1.81818182,5.48130659 1.81818182,10 C1.81818182,14.5186934 5.48130659,18.1818182 10,18.1818182 C14.5186934,18.1818182 18.1818182,14.5186934 18.1818182,10 C18.1818182,5.48130659 14.5186934,1.81818182 10,1.81818182 Z M10.4090909,5.5 L10.4090909,9.59090909 L14.5,9.59090909 L14.5,10.4090909 L10.4090909,10.4090909 L10.4090909,14.5 L9.59090909,14.5 L9.59090909,10.4090909 L5.5,10.4090909 L5.5,9.59090909 L9.59090909,9.59090909 L9.59090909,5.5 L10.4090909,5.5 Z" id="Contact-Add" fill="#FECDD2"></path>
                        <g id="plus-circle-light" transform="translate(1.000000, 1.000000)" fill="#FECDD2" fill-rule="nonzero">
                            <path d="M13.6451613,8.78225806 L13.6451613,9.21774194 C13.6451613,9.45725806 13.4491935,9.65322581 13.2096774,9.65322581 L9.65322581,9.65322581 L9.65322581,13.2096774 C9.65322581,13.4491935 9.45725806,13.6451613 9.21774194,13.6451613 L8.78225806,13.6451613 C8.54274194,13.6451613 8.34677419,13.4491935 8.34677419,13.2096774 L8.34677419,9.65322581 L4.79032258,9.65322581 C4.55080645,9.65322581 4.35483871,9.45725806 4.35483871,9.21774194 L4.35483871,8.78225806 C4.35483871,8.54274194 4.55080645,8.34677419 4.79032258,8.34677419 L8.34677419,8.34677419 L8.34677419,4.79032258 C8.34677419,4.55080645 8.54274194,4.35483871 8.78225806,4.35483871 L9.21774194,4.35483871 C9.45725806,4.35483871 9.65322581,4.55080645 9.65322581,4.79032258 L9.65322581,8.34677419 L13.2096774,8.34677419 C13.4491935,8.34677419 13.6451613,8.54274194 13.6451613,8.78225806 Z M18,9 C18,13.9717742 13.9717742,18 9,18 C4.02822581,18 0,13.9717742 0,9 C0,4.02822581 4.02822581,0 9,0 C13.9717742,0 18,4.02822581 18,9 Z M16.8387097,9 C16.8387097,4.64879032 13.3076613,1.16129032 9,1.16129032 C4.64879032,1.16129032 1.16129032,4.69233871 1.16129032,9 C1.16129032,13.3512097 4.69233871,16.8387097 9,16.8387097 C13.3512097,16.8387097 16.8387097,13.3076613 16.8387097,9 Z" id="Shape"></path>
                        </g>
                    </g>
                    <g id="pridat" transform="translate(935.000000, 174.000000)">
                        <circle id="Oval" fill="#443E48" cx="10" cy="10" r="10"></circle>
                        <path d="M10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 Z M10,1.81818182 C5.48130659,1.81818182 1.81818182,5.48130659 1.81818182,10 C1.81818182,14.5186934 5.48130659,18.1818182 10,18.1818182 C14.5186934,18.1818182 18.1818182,14.5186934 18.1818182,10 C18.1818182,5.48130659 14.5186934,1.81818182 10,1.81818182 Z" id="Contact-Add" fill="#FECDD2"></path>
                        <g id="minus-circle-light" transform="translate(1.000000, 1.000000)" fill-rule="nonzero">
                            <path d="M18,9 C18,13.9717742 13.9717742,18 9,18 C4.02822581,18 0,13.9717742 0,9 C0,4.02822581 4.02822581,0 9,0 C13.9717742,0 18,4.02822581 18,9 Z" id="Shape" fill="#FECDD2"></path>
                            <g id="Group" transform="translate(4.000000, 8.000000)" fill="#443E48">
                                <path d="M0.790322581,1.65322581 C0.550806452,1.65322581 0.35483871,1.45725806 0.35483871,1.21774194 L0.35483871,0.782258065 C0.35483871,0.542741935 0.550806452,0.346774194 0.790322581,0.346774194 L9.20967742,0.346774194 C9.44919355,0.346774194 9.64516129,0.542741935 9.64516129,0.782258065 L9.64516129,1.21774194 C9.64516129,1.45725806 9.44919355,1.65322581 9.20967742,1.65322581 L0.790322581,1.65322581 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                    <g id="pridat" transform="translate(935.000000, 207.000000)">
                        <circle id="Oval" fill="#443E48" cx="10" cy="10" r="10"></circle>
                        <path d="M10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 Z M10,1.81818182 C5.48130659,1.81818182 1.81818182,5.48130659 1.81818182,10 C1.81818182,14.5186934 5.48130659,18.1818182 10,18.1818182 C14.5186934,18.1818182 18.1818182,14.5186934 18.1818182,10 C18.1818182,5.48130659 14.5186934,1.81818182 10,1.81818182 Z M10.4090909,5.5 L10.4090909,9.59090909 L14.5,9.59090909 L14.5,10.4090909 L10.4090909,10.4090909 L10.4090909,14.5 L9.59090909,14.5 L9.59090909,10.4090909 L5.5,10.4090909 L5.5,9.59090909 L9.59090909,9.59090909 L9.59090909,5.5 L10.4090909,5.5 Z" id="Contact-Add" fill="#FECDD2"></path>
                    </g>
                    <path d="M949.645161,216.782258 L949.645161,217.217742 C949.645161,217.457258 949.449194,217.653226 949.209677,217.653226 L945.653226,217.653226 L945.653226,221.209677 C945.653226,221.449194 945.457258,221.645161 945.217742,221.645161 L944.782258,221.645161 C944.542742,221.645161 944.346774,221.449194 944.346774,221.209677 L944.346774,217.653226 L940.790323,217.653226 C940.550806,217.653226 940.354839,217.457258 940.354839,217.217742 L940.354839,216.782258 C940.354839,216.542742 940.550806,216.346774 940.790323,216.346774 L944.346774,216.346774 L944.346774,212.790323 C944.346774,212.550806 944.542742,212.354839 944.782258,212.354839 L945.217742,212.354839 C945.457258,212.354839 945.653226,212.550806 945.653226,212.790323 L945.653226,216.346774 L949.209677,216.346774 C949.449194,216.346774 949.645161,216.542742 949.645161,216.782258 Z M954,217 C954,221.971774 949.971774,226 945,226 C940.028226,226 936,221.971774 936,217 C936,212.028226 940.028226,208 945,208 C949.971774,208 954,212.028226 954,217 Z M952.83871,217 C952.83871,212.64879 949.307661,209.16129 945,209.16129 C940.64879,209.16129 937.16129,212.692339 937.16129,217 C937.16129,221.35121 940.692339,224.83871 945,224.83871 C949.35121,224.83871 952.83871,221.307661 952.83871,217 Z" id="Shape" fill="#FECDD2" fill-rule="nonzero"></path>
                    <g id="Group-6" transform="translate(93.000000, 164.000000)">
                        <text id="Upraví-to-text" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.0231764706" y="23">Upraví to text</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(207.500000, 18.500000) rotate(-90.000000) translate(-207.500000, -18.500000) translate(202.000000, -59.000000)" stroke-linecap="round" stroke-width="3">
                            <line x1="6.25" y1="0" x2="5.5" y2="93.5" id="Path" stroke="#FAD325" stroke-dasharray="4,7"></line>
                            <line x1="5.5" y1="146.5" x2="5.5" y2="93.5" id="Path" stroke="#1E2432" stroke-dasharray="4,7"></line>
                            <polyline id="Path-2" stroke="#1E2432" stroke-linejoin="round" transform="translate(5.500000, 152.500000) rotate(90.000000) translate(-5.500000, -152.500000) " points="3 158 8 152.761905 3 147"></polyline>
                        </g>
                    </g>
                    <g id="Group-6" transform="translate(0.000000, 303.000000)">
                        <text id="Označí-to-řádek-pro" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="0.0404705882" y="23">Označí to řádek pro úpravy</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(261.000000, 18.500000) rotate(-90.000000) translate(-261.000000, -18.500000) translate(255.500000, -19.500000)" stroke="#FAD325" stroke-linecap="round" stroke-width="3">
                            <line x1="6.25" y1="0" x2="5.5" y2="65.5" id="Path" stroke-dasharray="4,7"></line>
                            <polyline id="Path-2" stroke-linejoin="round" transform="translate(5.500000, 73.500000) rotate(90.000000) translate(-5.500000, -73.500000) " points="3 79 8 73.7619048 3 68"></polyline>
                        </g>
                    </g>
                    <g id="Group-5" transform="translate(468.000000, 0.000000)">
                        <text id="Upraví-to-akord" font-family="Pacifico-Regular, Pacifico" font-size="18" font-weight="normal" letter-spacing="0.0370588235" fill="#FAD325">
                            <tspan x="72" y="23">Upraví to akord</tspan>
                        </text>
                        <g id="Arrow-/-Angled-Dashed" transform="translate(29.000000, 72.500000) rotate(90.000000) translate(-29.000000, -72.500000) translate(-27.500000, 43.500000)" stroke-linecap="round" stroke-width="3">
                            <path d="M0,0 L0,42.5 C6.76353751e-16,48.0228475 4.4771525,52.5 10,52.5 L47,52.5 L47,52.5" id="Path" stroke="#FAD325" stroke-dasharray="4,7"></path>
                            <path d="M43,53 L104,52.5 L104,52.5 L43,53 Z" id="Path" stroke="#1E2432" stroke-dasharray="4,11" transform="translate(73.500000, 52.750000) scale(-1, 1) translate(-73.500000, -52.750000) "></path>
                            <polyline id="Path-2" stroke="#1E2432" stroke-linejoin="round" points="108 58 112.5 53 108 47.5"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>`
};