import { ADD_TO_HISTORY } from '../actionTypes';

const initialState = {
    lastVisited: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_HISTORY: {
            let songId = action.payload.songId;
            // remove if exists and add to the end
            const history = [...state.lastVisited].filter(item => item !== songId)
            history.unshift(action.payload.songId);
            return {
                ...state, lastVisited: history
            };
        }
        default:
            return state;
    }
}
