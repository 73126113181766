import React, { useEffect, useState } from 'react';
import ShowAccord from "./ShowAccord";
import { accords } from "../data/chords";

const ShowAccords = ({ chords, instrument }) => {

  // chords info 
  const [usedChords, setUsedChords] = useState([]);
  const [fixed, setFixed] = useState(false);
  //const [fixedPosition, setFixedPosition] = useState(0);
  //const [topOffest, setTopOffset] = useState(0);
  var wrap;
  var songBody;
  var offsetTop;

  useEffect(() => {
    var used = {};

    if (typeof chords === 'object' && chords !== null) {
      Object.keys(chords).forEach((k) => {
        const row = chords[k];
        if (row && row.length) {
          row.forEach((ch) => {
            if (ch && ch.chordName) {

              if (accords[instrument][ch.chordName]) {
                used[ch.chordName] = ch.chordName;
              }

            }
          });
        }
      });
    }

    setUsedChords(Object.keys(used));

  }, [chords]);

  useEffect(() => {

    wrap = document.getElementById("used-chords-wrap");
    songBody = document.getElementById("songbody");

    offsetTop = songBody.getBoundingClientRect().top + window.scrollY;
    window.addEventListener("scroll", scrolling);

    const handleResize = () => {
      offsetTop = songBody.offsetTop;
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", scrolling)
      window.removeEventListener("resize", handleResize);
    }
  })

  const scrolling = () => {
    if (!offsetTop) {
      offsetTop = songBody.getBoundingClientRect().top + window.scrollY;
    }
    if (window.scrollY > offsetTop) {
      wrap.classList.add("fixed")
      wrap.style.top = (window.scrollY - offsetTop + 70) + "px";
    } else {
      wrap.classList.remove("fixed")
      wrap.style.top = "0px";
    }
  }

  if (!chords) {
    return '';
  }

  return (
    <div id="used-chords-wrap" className="used-chords">

      {
        usedChords.map((chordName, row) => (
          <div className="acc-info" key={`acrdf${row}`}>
            <strong>{chordName}</strong>
            <ShowAccord key={`chord-accord-info-${row}`} small={true} chordName={chordName} instrument={instrument} />
          </div>
        ))
      }


    </div>
  );
}

export default ShowAccords;