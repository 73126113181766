import React from 'react';
import { useDispatch } from "react-redux";
import { instruments } from "../data/chords";

const SelectInstument = ({ song, instrument }) => {

    const dispatch = useDispatch();

    const setInstrument = instrument => {
        dispatch({
            type: "SET_INSTRUMENT",
            payload: instrument
        });
    };

    const isSelected = instrumentIdent => {
        return instrument === instrumentIdent;
    };

    return (
        <div className="instruments">
            {instruments.map((instr) => (
                <button
                    className={`isntr ${instr.key} ${isSelected(instr.key) ? "selected" : ""}`}
                    onClick={e => setInstrument(instr.key)}
                    key={`intr-${instr.key}`}
                >
                    {instr.name}
                </button>
            ))}
        </div>
    );
}

export default SelectInstument;