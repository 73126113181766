import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import { loginUser, logoutUser } from "../../redux/actions"
import { isUserLoggedIn, getUser } from "../../redux/selectors"

//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GoogleLogin from "react-google-login"
/*
RewriteEngine On 

Header add Access-Control-Allow-Origin "*"
to
Header add Access-Control-Allow-Origin "http://www.yourwebsite.com"


Header add Access-Control-Allow-Headers "origin, x-requested-with, content-type"
Header add Access-Control-Allow-Methods "PUT, GET, POST, DELETE, OPTIONS"

RewriteCond %{REQUEST_FILENAME} !-f
RewriteRule ^(.*)$ index.php [QSA,L]


<script>
  window.fbAsyncInit = function() {
    FB.init({
      appId      : '{your-app-id}',
      cookie     : true,
      xfbml      : true,
      version    : '{api-version}'
    }); 
      
    FB.AppEvents.logPageView();   
      
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
</script>

fb
app secret ac3e78bac4ecd941b434245bdb1f5674

google
client id
209468385901-t3q9s3t6pp0thbnlgmb4bp2f99scq4ll.apps.googleusercontent.com
client secret
PNU_H5wGDXb9jFANVKc2W9fs

*/

const TopRow = ({ props, loggedIn, user, loginUser, logoutUser }) => {
  const [hightlightLogin, setHightlightLogin] = useState(false);
  //const location = useLocation();



  useEffect(() => {
    console.log('www')
  })

  const needsLoginOver = () => {
    if (!loggedIn) {
      setHightlightLogin(true)
    }

  }

  const needsLoginOut = () => {
    setHightlightLogin(false)
  }

  const signUp = async (res, type) => {
    //return false 

    let postData
    if (type === "facebook" && res.email) {
      postData = {
        provider: type,
        name: res.name,
        email: res.email,
        user_id: res.id,
        avatar: res.picture.data.url,
        token: res.accessToken,
      }
    }

    if (type === "google" && res.profileObj && res.profileObj.email) {
      postData = {
        provider: type,
        name: res.profileObj.name,
        email: res.profileObj.email,
        user_id: res.googleId,
        avatar: res.profileObj.imageUrl,
        token: res.accessToken,
      }
    }

    if (postData && postData.email) {
      const loginStatus = await loginUser(postData)
      console.log(loginStatus)
      console.log("!!!!")
      console.log(postData)
    }
  }

  const responseFacebook = response => {
    signUp(response, "facebook")
  }

  const responseGoogle = response => {
    signUp(response, "google")
  }

  const logOut = (e) => {
    e.preventDefault();
    logoutUser();

  }

  return (
    <div className={`user-row ${loggedIn ? 'user-logged' : 'user-not-logged'}`} id="userRow">
      <div className="actions left">
        {props.back && (
          <ul>
            <li>
              <NavLink to={props.back} className="fill back">
                {props.backTitle}
              </NavLink>
            </li>
          </ul>
        )}
        {props.backCallBack && (
          <ul>
            <li>
              <a onClick={props.backCallBack} className="fill back">
                {props.backTitle}
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className={`actions right ${loggedIn ? 'user-logged' : 'user-not-logged'}`}>
        <ul>
          <li>
            <NavLink
              onMouseEnter={() => needsLoginOver()}
              onMouseLeave={() => needsLoginOut()}
              to={loggedIn ? '/pridat-pisnicku' : '#'}
              className="fill add-song"
            >
              Přidat písničku
            </NavLink>
          </li>
          <li>
            <NavLink
              onMouseEnter={() => needsLoginOver()}
              onMouseLeave={() => needsLoginOut()}
              to={loggedIn ? '/historie' : '#'}
              className="fill history"
            >
              Historie
            </NavLink>
          </li>
          {loggedIn && (
            <li>
              <NavLink to="/muj-zpevnik" className="fill logged-in" title="Můj zpěvník">
                Můj zpěvník
                {user.avatar && (
                  <figure>
                    <img src={user.avatar} alt={user.name} />
                  </figure>
                )}
              </NavLink>

              <nav>
                <ul>
                  <li>
                    <NavLink to="/" onClick={(e) => logOut(e)} className="fill logout">
                      Odhlásit se
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </li>
          )}
          {!loggedIn && (
            <li className={hightlightLogin ? 'show-nav' : ''}>
              <a className={`login fill not-logged-in ${hightlightLogin ? 'hightlight' : ''}`} href="#" title="Příhlásit se">
                Můj zpěvník
              </a>
              <nav>
                <ul>
                  <li>
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      autoLoad={false}
                      language="cs_CZ"
                      render={renderProps => (
                        <button className="btn login facebook" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                          Přihlásit pomocí Facebook
                        </button>
                      )}
                    />
                  </li>
                  <li>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      autoLoad={false}
                      isSignedIn={false}
                      render={renderProps => (
                        <button className="btn login gplus" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                          Přihlásit pomocí Google
                        </button>
                      )}
                    />
                  </li>
                </ul>
              </nav>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
const mapStateToProps = (state, props) => ({
  props: props,
  loggedIn: isUserLoggedIn(state),
  user: getUser(state),
})

export default connect(mapStateToProps, { loginUser, logoutUser })(TopRow)
