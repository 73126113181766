import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { getUserSongsList, getUserPlaylist, isUserLoggedIn, getUser, getTagByRouteSlug, getUserSongsCount } from "./redux/selectors"
import { updateUser, deleteUser } from "./redux/actions"
import Modal from "react-modal"

/* Custom components */
import TopRow from "./components/user/TopRow"
import SongRow from "./components/SongRow"
import Search from "./components/Search"
import Alphabet from "./components/Alphabet"
import SongsCount from "./components/SongsCount"
import { Redirect, NavLink } from "react-router-dom"

const UserProfile = ({ props, songs, songsCount, loggedIn, user, playlist, tag: selectedTag, updateUser, deleteUser }) => {
    /// deconstruct the state

    const [editMode, setEditMode] = useState(false)
    const [profileName, setProfileName] = useState(user ? user.name : '')

    // modal
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [modalVisibleClass, setModalVisibleClass] = useState("")

    const handleChangeTitle = event => {
        setProfileName(event.target.value)
    }

    /* Modals */
    const handleOpenModal = () => {
        setShowConfirmModal(true)
        setTimeout(() => {
            setModalVisibleClass("visible")
        }, 100)
    }

    const handleCloseModal = () => {
        setModalVisibleClass("")
        setTimeout(() => {
            setShowConfirmModal(false)
        }, 100)
    }

    const saveChanges = async () => {
        updateUser({ name: profileName })
        setEditMode(false)
    }

    const deleteProfile = async () => {
        deleteUser()
        setEditMode(false)
    }

    useEffect(() => { }, [])

    if (!loggedIn) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <TopRow back="/pisnicky" backTitle="Zpět" />


            <div className="title" id="header">
                {editMode ? <input type="text" name="profile" placeholder="Jméno" value={profileName} onChange={event => handleChangeTitle(event)} /> : <h1>Můj zpěvník - {profileName}</h1>}
                <h2>{user.email}</h2>
                <NavLink to={`/${user.public_url}`} className="nice-link">
                    {process.env.REACT_APP_URL}/{user.public_url}
                </NavLink>
            </div>

            <Search {...props} baseUrl={true} />
            <Alphabet {...props} baseUrl={true} />

            <div className="edit-toolbar">
                {!editMode ? (
                    <button className="edit fill" onClick={() => setEditMode(true)}>
                        Upravit můj profil
                    </button>
                ) : (
                        <React.Fragment>
                            <button className="remove fill" onClick={handleOpenModal}>
                                Smazat můj účet
                            </button>
                            <button className="save fill b" onClick={saveChanges}>
                                Uložit úpravy
                            </button>
                        </React.Fragment>
                    )}
            </div>

            <Modal isOpen={showConfirmModal} onRequestClose={handleCloseModal} className={`modal ${modalVisibleClass}`} overlayClassName="overlay">
                <div class="content">
                    {/* <a href="#" className="close" onClick={handleCloseModal}></a> */}
                    <button className="close" onClick={handleCloseModal} />
                    <div class="inner-content">
                        <h3>Váš účet a zpěvník bude nanávratně smazán</h3>
                        <p>Chcete pokračovat?</p>

                        <div className="btns">
                            <button className="confirm-delete" onClick={deleteProfile}>
                                Ano
                            </button>
                            <button className="reject" onClick={handleCloseModal}>
                                Ne
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="tab-content">

                <SongsCount count={songsCount} />

                {songs.map(grouped => (
                    <div className="tab-pane" key={grouped.group} id={`letter-${grouped.group}`}>
                        <h2>{grouped.group}</h2>
                        <ul className={`itemlist ${loggedIn ? "logged-in" : ""}`}>
                            {grouped.children.map(song => (
                                <SongRow key={`song-${song.id}`} song={song} selectedTag={selectedTag} user={user} playlist={playlist} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state, props) => ({
    props: props,
    songs: getUserSongsList(state, props),
    tag: getTagByRouteSlug(state, props),
    songsCount: getUserSongsCount(state, props),
    loggedIn: isUserLoggedIn(state),
    user: getUser(state),
    playlist: getUserPlaylist(state),
})

export default connect(mapStateToProps, { updateUser, deleteUser })(UserProfile)
