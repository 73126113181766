export const UPDATE_SONG = "UPDATE_SONG";
export const ADD_SONG = "ADD_SONG";
export const RESET_NEW_SONG = "RESET_NEW_SONG";
export const DELETE_SONG = "DELETE_SONG";
export const LOAD_SONGS = "LOAD_SONGS";
export const UPDATE_CHORDS = "UPDATE_CHORDS";
export const UPDATE_ROWS = "UPDATE_ROWS";
export const UPDATE_TRANSPOSITION = "UPDATE_TRANSPOSITION";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_AUTHOR = "UPDATE_AUTHOR";

export const LOAD_TAGS = "LOAD_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";

export const SEARCH_TEXT = "SEARCH_TEXT";
export const FILTER_TAG = "FILTER_TAG";
export const FILTER_LETTER = "FILTER_LETTER";

export const ADD_TO_HISTORY = "ADD_TO_HISTORY";

export const SET_INSTRUMENT = "SET_INSTRUMENT";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_USER = "SET_USER";

export const ADD_TO_PLAYLIST = "ADD_TO_PLAYLIST";
export const REMOVE_FROM_PLAYLIST = "REMOVE_FROM_PLAYLIST";

export const UPVOTE_SONG = "UPVOTE_SONG";
export const DOWNVOTE_SONG = "DOWNVOTE_SONG";

