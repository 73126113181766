import React, { useState } from "react"
import { NavLink, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import TopRow from "./components/user/TopRow"
import { isUserLoggedIn } from "./redux/selectors"

import svgs from "./data/svgs"

const Homepage = ({ songs, loggedIn }) => {
  /// deconstruct the state

  const [slide, setSlide] = useState(1)

  if (loggedIn) {
    return <Redirect to={"/pisnicky"} />;
  }

  return (
    <React.Fragment>
      <TopRow />
      <div className="homepage">
        <div className="intro">
          <h1>Můj zpěvník</h1>
          <h2>{songs.length} písniček pro vaší kytaru či uke</h2>

          <div className="crossroads">
            <a className="button big b" href="#how-it-works">
              Ukázat jak to funguje
            </a>
            <NavLink to={"/pisnicky"} className="button big b">
              Jdeme na to
            </NavLink>
          </div>
        </div>
        <div className="heads-up">
          <article>
            <h3 className="lg">Jde to i bez reklam</h3>
            <p>
              Chápeme, že člověk se nějk živit musí. Rozhodně nechceme spílat nad podobně zaměřenými weby, které si tímto způsobem (při)vydělávají. Naopak - děkujeme jménem celé
              hudební rodiny za více či méně dobře odvedenou práci. Jenom jsme to chtěli pojmout po svém.
            </p>
          </article>
          <article>
            <h3 className="lg">Váš zpěvník offline</h3>
            <p>
              Nejlepší zpevník je ten, co máte s sebou. U ohně bez signálu, ve vlaku do Tater či na Azorských ostrovech. Díky tomu si můžete brnkat, cvičit nebo konzertovat
              kdekoliv budete chtít.
            </p>
          </article>
          <article>
            <h3 className="lg">Je to zdarma</h3>
            <p>
              Říká se, že nejlepší věci v životě jsou zdarma. My máme rádi nejlepší věci. Život. I zdarma. Navíc používáním Vašeho zpevníku, svým hlasem uděleným na základě
              zkopírované písničky či vlastní tvorbou podporujete nadšence jako jste vy. "Happy days." :)
            </p>
          </article>
        </div>

        <div className="how-it-works" id="how-it-works">
          <div className="crossroads">
            <a className={`button big b ${slide === 1 ? "active" : ""}`} onClick={e => setSlide(1)}>
              Vyhledávání v seznamu
            </a>
            <a className={`button big b ${slide === 2 ? "active" : ""}`} onClick={e => setSlide(2)}>
              úprava písničky
            </a>
          </div>
          <ul className={`help slide-${slide}`}>
            <li dangerouslySetInnerHTML={{ __html: svgs.helpSearch }}></li>
            <li dangerouslySetInnerHTML={{ __html: svgs.helpEdit }}></li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state, props) => ({
  props: props,
  songs: props.songs,
  loggedIn: isUserLoggedIn(state)
})

export default connect(mapStateToProps)(Homepage)
