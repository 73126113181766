import { useState, useEffect } from "react";

const useResize = () => {
  //   const [smallPad, setSmallPad] = useState(true);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () =>
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  /*   const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useWindow("resize", () =>
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  ); */
  return size;
};
export default useResize;
