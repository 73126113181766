import {
  LOAD_SONGS,
  ADD_SONG,
  RESET_NEW_SONG,
  DELETE_SONG,
  UPDATE_SONG,
  UPDATE_CHORDS,
  UPDATE_ROWS,
  LOAD_TAGS,
  SEARCH_TEXT,
  FILTER_TAG,
  FILTER_LETTER,
  ADD_TO_HISTORY,
  UPDATE_TAGS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_USER_ERROR,
  UPDATE_USER,
  REMOVE_USER,
  SET_USER,
  ADD_TO_PLAYLIST,
  REMOVE_FROM_PLAYLIST,
  UPVOTE_SONG,
  DOWNVOTE_SONG,
} from "./actionTypes"
import store from "../redux/store"
import { localStorageSave } from "../localStorage"

export const updateSong = data => ({
  type: UPDATE_SONG,
  payload: data,
})

export const updateChords = data => ({
  type: UPDATE_CHORDS,
  payload: data,
})

export const updateRows = data => ({
  type: UPDATE_ROWS,
  payload: data,
})

export const updateTags = data => ({
  type: UPDATE_TAGS,
  payload: data,
})

export const addSongToHistory = data => ({
  type: ADD_TO_HISTORY,
  payload: data,
})

export const loadTags = data => ({
  type: LOAD_TAGS,
  payload: data,
})

export const getTags = () => async dispatch => {
  const state = store.getState()
  var data
  if (state.tags && state.tags.tagsList && state.tags.tagsList.length) {
    data = state.tags.tagsList
  } else {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    })
    data = await res.json()
  }

  dispatch({ type: LOAD_TAGS, payload: data })
}

export const getSongs = () => async dispatch => {
  const state = store.getState()
  var data
  /*
  if (state.songs && state.songs.songsList && state.songs.songsList.length) {
      data = state.songs.songsList
  } else {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/songs`)
      data = await res.json()
  }
  */

  const res = await fetch(`${process.env.REACT_APP_API_URL}/songs`)
  data = await res.json()

  dispatch({ type: LOAD_SONGS, payload: data })
}

export const deleteSong = id => async dispatch => {
  //const state = store.getState();

  const res = await fetch(`${process.env.REACT_APP_API_URL}/delete-song/${id}`, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  })

  dispatch({ type: DELETE_SONG, payload: id })

  return true
}


export const resetNewSong = data => ({
  type: RESET_NEW_SONG,
  payload: true,
})


export const saveSong = data => async dispatch => {
  //const state = store.getState();

  const res = await fetch(`${process.env.REACT_APP_API_URL}/save-song`, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: JSON.stringify(data),
  })

  const response = await res.json()

  // todo check for errors

  if (data.id !== response.id) {
    // new song
    dispatch({ type: ADD_SONG, payload: response })
  }

  return response
}

export const parseSong = url => async dispatch => {
  //const state = store.getState();

  const data = { song_url: url }

  const res = await fetch(`${process.env.REACT_APP_API_URL}/parse-song`, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: JSON.stringify(data),
  })
  const response = await res.json()

  return response
}

export const filterSongsByTag = tagId => dispatch => {
  dispatch({ type: FILTER_TAG, payload: tagId })
}

export const filterSongsByLetter = letter => dispatch => {
  dispatch({ type: FILTER_LETTER, payload: letter })
}

export const searchSongs = text => dispatch => {
  dispatch({ type: SEARCH_TEXT, payload: text })
}

/* user actions */

export const validateUser = data => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/validate`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    }
  })
  const response = await res.json()
  return response.id;
}


export const loginUser = data => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/social-login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  const response = await res.json()

  if (response.errors) {
    dispatch({ type: LOGIN_USER_ERROR, payload: response })
  } else {
    dispatch({ type: LOGIN_USER, payload: response })
  }
}

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER, payload: true })
  window.location.href = "/";
}

export const updateUser = data => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/update`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: JSON.stringify(data),
  })

  const response = await res.json()

  dispatch({
    type: UPDATE_USER,
    payload: response,
  })
}

export const deleteUser = song => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: "",
  })

  const response = await res.json()

  dispatch({
    type: REMOVE_USER,
    payload: true,
  })

  window.location.href = "/";

}

export const addToPlaylist = song => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/playlist/${song.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: song.id,
  })

  const response = await res.json()

  dispatch({
    type: ADD_TO_PLAYLIST,
    payload: song.id,
  })
}

export const removeFromToPlaylist = song => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/playlist/${song.id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: song.id,
  })

  const response = await res.json()

  dispatch({
    type: REMOVE_FROM_PLAYLIST,
    payload: song.id,
  })
}

export const upVote = song => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/song/upvote/${song.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: song.id,
  })

  const response = await res.json()

  localStorageSave("voted_" + song.id, "1")

  if (response && response.voted) {
    dispatch({
      type: UPVOTE_SONG,
      payload: song.id,
    })
  }
}

export const downVote = song => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/song/downvote/${song.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: song.id,
  })

  localStorageSave("voted_" + song.id, "1")

  const response = await res.json()

  if (response && response.voted) {
    dispatch({
      type: DOWNVOTE_SONG,
      payload: song.id,
    })
  }
}


export const getUserByUrl = (url) => async dispatch => {

  const res = await fetch(`${process.env.REACT_APP_API_URL}/user/by-url/${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
  })

  const response = await res.json()

  if (response && response.user) {
    dispatch({ type: SET_USER, payload: response })
  }
  return response;
}

export const getSong = (id) => async dispatch => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/song/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
  })

  const response = await res.json()
  console.log(response)
  if (response && response.user) {
    //dispatch({ type: SET_USER, payload: response })
  }
  return response;
}