
import React from 'react';

const SongTitle = ({ song, songTitle, setSongTitle, songAuthor, setSongAuthor, editMode }) => {


    const handleChangeTitle = (event) => {
        setSongTitle(event.target.value);
    }
    const handleChangeAuthor = (event) => {
        setSongAuthor(event.target.value);
    }

    return (
        <div className="title" id="header">
            {editMode ?
                (<input
                    type="text"
                    name="song_title"
                    placeholder="Název"
                    value={songTitle}
                    onChange={(event) => handleChangeTitle(event)}
                />) :
                (<h1>{song.name}</h1>)
            }
            {editMode ?
                (<input
                    type="text"
                    name="song_author"
                    placeholder="Autor"
                    value={songAuthor}
                    onChange={(event) => handleChangeAuthor(event)}
                />) :
                (<h2>{song.author}</h2>)
            }
        </div>
    );
}

export default SongTitle;