import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { filterSongsByTag } from "../redux/actions"
import { getTagsFromState, getTagByRouteSlug } from "../redux/selectors"
import { NavLink } from "react-router-dom"

const TagsFilter = ({ props, basePath, allPath, tagsList, tag, filterSongsByTag }) => {

    const [selectedTag, setSelectedTag] = useState(tag)

    useEffect(() => {
        setSelectedTag(tag)

        if (localStorage.getItem('changing_letter') === "1") {
            // already reseted by changing letter, prevent duplicate dispatch
        } else {
            filterSongsByTag(tag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag])

    const secondClick = (isActive, event) => {
        localStorage.setItem('changing_tag', "1")
        localStorage.setItem('changing_letter', "0")
        if (isActive) {
            // second click to reset
            event.preventDefault()
            setSelectedTag(false)
            filterSongsByTag(false)
            props.history.push(allPath)
        }
    }

    return (
        <div className="tags in-search">
            {tagsList.map(tag => (
                //  <button className={`tag ${selectedTag === tag.id ? 'selected' : ''}`} onClick={(e) => filter(tag.id)} key={`tag-${tag.id}`} style={{ "--tag-color": tag.color }}>{tag.title}</button>

                <NavLink
                    to={`${basePath}kategorie/${tag.slug}`}
                    onClick={e => secondClick(selectedTag === tag.id, e)}
                    className={`tag ${selectedTag === tag.id ? "selected" : ""}`}
                    key={`tag-${tag.id}`}
                    style={{ "--tag-color": tag.color }}
                >
                    {tag.title}
                </NavLink>
            ))}
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    props: props,
    basePath: props.basePath,
    allPath: props.allPath,
    tagsList: getTagsFromState(state),
    tag: getTagByRouteSlug(state, props),
})

export default connect(mapStateToProps, { filterSongsByTag })(TagsFilter)
