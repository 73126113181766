import React, { useState } from "react"
import { useDispatch, connect } from "react-redux"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import { getTagsFromState } from "../redux/selectors"

const SongTags = ({ props, tagsList, editMode, isTagSelected, addRemoveTag }) => {


    return (
        tagsList &&
        tagsList.length && (
            <div className={`tags_select ${editMode ? "edit" : ""}`}>
                <TransitionGroup className="tags">
                    {tagsList
                        .filter(t => {
                            return isTagSelected(t.id)
                        })
                        .map(tag => (
                            <CSSTransition key={tag.id} timeout={500} classNames="item">
                                <button className="tag selected" onClick={e => addRemoveTag(tag.id)} key={`tag-${tag.id}`}>
                                    {tag.title}
                                </button>
                            </CSSTransition>
                        ))}
                </TransitionGroup>
                <TransitionGroup className="tags">
                    {tagsList
                        .filter(t => {
                            return !isTagSelected(t.id)
                        })
                        .map(tag => (
                            <CSSTransition key={tag.id} timeout={500} classNames="item">
                                <button className="tag" onClick={e => addRemoveTag(tag.id)} key={`tag-${tag.id}`}>
                                    {tag.title}
                                </button>
                            </CSSTransition>
                        ))}
                </TransitionGroup>
            </div>
        )
    )
}

const mapStateToProps = (state, props) => ({
    props: props,
    tagsList: getTagsFromState(state),
})

export default connect(mapStateToProps, {})(SongTags)
