import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

/* Custom components */
import SongRow from './SongRow';


const SongsGroup = ({ song, selectedTag, user, playlist, songGroups }) => {


    useEffect(() => {
    }, []);

    const [expanded, seExpanded] = useState(false)

    const expandCoplapse = event => {
        event.stopPropagation();
        event.preventDefault();
        seExpanded(!expanded);
    }

    const groupedSongs = songGroups[song.id];

    if (groupedSongs) {

        return (
            <li className={`expandable ${expanded ? 'expanded' : ''}`}>
                <SongRow key={`song-${song.id}`} song={song} selectedTag={selectedTag} user={user} playlist={playlist} expandable={true} expandCoplapse={expandCoplapse} />
                {groupedSongs.map(s => (
                    <SongRow key={`song-${s.id}`} song={s} selectedTag={selectedTag} user={user} playlist={playlist} />
                ))}
            </li>
        )
    }

    if (!groupedSongs && song.original_id > 0) {
        return '';
    }

    return (
        <li>
            <SongRow key={`song-${song.id}`} song={song} selectedTag={selectedTag} user={user} playlist={playlist} />
        </li>
    );
};
const mapStateToProps = (state, props) => (
    {

    }
);

export default connect(mapStateToProps)(SongsGroup);

