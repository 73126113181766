import {
    LOAD_SONGS,
    ADD_SONG,
    RESET_NEW_SONG,
    DELETE_SONG,
    UPDATE_SONG,
    UPDATE_CHORDS,
    UPDATE_ROWS,
    SEARCH_TEXT,
    FILTER_LETTER,
    FILTER_TAG,
    UPDATE_TAGS,
    UPDATE_TRANSPOSITION,
    UPDATE_NAME,
    UPDATE_AUTHOR,
    SET_INSTRUMENT,
    UPVOTE_SONG,
    DOWNVOTE_SONG
} from "../actionTypes";

import { getSongById, getSongIndexById, removeClonedSongs } from "../selectors";

const initialState = {
    songsList: [],
    songsListFiltered: [],
    userEditedSongs: [],
    searchText: "",
    tag: false,
    instrument: 'guitar',
    letter: ""
};

export const songPrototype = {
    id: 0,
    name: '',
    slug: '',
    author: '',
    rows: [{ text: '', length: 12 }],
    chords: [],
    transposition: 0,
    tags: []
}

export default function (state = initialState, action) {
    // console.log(action);
    switch (action.type) {


        case RESET_NEW_SONG: {

            let songsList = [...state.songsList];

            // remove default new song
            let index = getSongIndexById(songsList, 0);
            songsList.splice(index, 1);
            // add default one again
            songsList.push({ ...songPrototype });

            return {
                ...state,
                songsList: songsList
            };
        }

        case ADD_SONG: {

            /*
            let newSong = { ...getSongById(state.songsList, 0) };
            newSong.id = action.payload.id;
            newSong.slug = action.payload.slug;
            */

            let newSong = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];

            // add new song
            songsList.push(newSong);
            songsListFiltered.push(newSong);

            // remove default new song
            let index = getSongIndexById(songsList, 0);
            songsList.splice(index, 1);

            let index2 = getSongIndexById(songsListFiltered, 0);
            songsListFiltered.splice(index2, 1);

            // add default one again
            songsList.push({ ...songPrototype });
            songsListFiltered.push({ ...songPrototype });

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }


        case DELETE_SONG: {
            const songId = action.payload;
            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];

            const index1 = getSongIndexById(songsList, songId);
            const index2 = getSongIndexById(songsListFiltered, songId);

            songsList.splice(index1, 1);
            songsListFiltered.splice(index2, 1);

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case UPDATE_SONG: {
            const song = action.payload;
            const songId = song.id;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            // eslint-disable-next-line no-unused-vars
            let songInList = getSongById(songsList, songId);
            // let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList = song;
            songsListFiltered = song;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }
        case UPDATE_CHORDS: {
            const { chords, songId } = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.chords = chords;
            songInListFiltered.chords = chords;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }
        case UPDATE_ROWS: {
            const { rows, songId } = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.rows = rows;
            songInListFiltered.rows = rows;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case UPDATE_TRANSPOSITION: {
            const { transposition, songId } = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.transposition = transposition;
            songInListFiltered.transposition = transposition;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case UPDATE_NAME: {
            const { name, songId } = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.name = name;
            songInListFiltered.name = name;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case UPDATE_AUTHOR: {
            const { author, songId } = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.author = author;
            songInListFiltered.author = author;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case UPDATE_TAGS: {
            // update songsList and songsListFiltered
            const { tags, songId } = action.payload;
            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];
            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            songInList.tags = tags;
            songInListFiltered.tags = tags;

            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };
        }

        case LOAD_SONGS: {
            // add empty song for new song 
            let newSongProto = getSongById(action.payload, 0);
            if (!newSongProto) {
                action.payload.push({ ...songPrototype });
            }

            return {
                ...state,
                songsList: action.payload,
                songsListFiltered: action.payload
            };
        }
        case SEARCH_TEXT:

            if (state.letter === action.payload.toLocaleLowerCase()) {
                return state;
            }

            let songsSearched = state.songsList.filter(song => {
                var ss = action.payload.toLocaleLowerCase();
                var name = song.name.toLocaleLowerCase();
                var author = song.author.toLocaleLowerCase();
                var name_norm = song.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                var author_norm = song.author.normalize('NFD').replace(/[\u0300-\u036f]/g, "");


                return (
                    name.search(ss) >= 0 ||
                    author.toLocaleLowerCase().search(ss) >= 0 ||
                    name_norm.toLocaleLowerCase().search(ss) >= 0 ||
                    author_norm.toLocaleLowerCase().search(ss) >= 0
                );
            });

            return {
                ...state,
                letter: "",
                tag: false,
                searchText: action.payload,
                songsListFiltered: songsSearched
            };

        case FILTER_TAG:


            if (state.tag === action.payload) {
                return state;
            }

            var songsFiltered = state.songsList;
            if (action.payload) {
                songsFiltered = state.songsList.filter(song => {
                    return (
                        song.tags && song.tags.filter(tag => tag.id === action.payload).length
                    );
                });
            }

            return {
                ...state,
                letter: "",
                tag: action.payload,
                searchText: "",
                songsListFiltered: songsFiltered
            };

        case FILTER_LETTER:
            const letter = action.payload.toUpperCase();
            let songsFilteredByLetter = [];

            if (state.letter === letter) {
                return state;
            }

            switch (letter) {
                case "*":
                case "":
                case false:
                    songsFilteredByLetter = state.songsList;
                    break;

                case "C": // to fix CH
                    songsFilteredByLetter = state.songsList.filter(song => {
                        return (
                            song.name.toUpperCase().startsWith(letter) &&
                            !song.name.toUpperCase().startsWith("CH")
                        );
                    });
                    break;
                default:
                    songsFilteredByLetter = state.songsList.filter(song => {
                        return song.name.toUpperCase().startsWith(letter);
                    });
                    break;
            }

            return {
                ...state,
                tag: false,
                searchText: "",
                letter: letter,
                songsListFiltered: songsFilteredByLetter
            };

        case SET_INSTRUMENT:
            return {
                ...state,
                instrument: action.payload
            };


        case UPVOTE_SONG:
        case DOWNVOTE_SONG:

            const songId = action.payload;

            let songsList = [...state.songsList];
            let songsListFiltered = [...state.songsListFiltered];

            let songInList = getSongById(songsList, songId);
            let songInListFiltered = getSongById(songsListFiltered, songId);

            if (action.type === DOWNVOTE_SONG) {
                songInList.votes_down -= 1;
            } else {
                songInList.votes_up += 1;
            }


            return {
                ...state,
                songsList: songsList,
                songsListFiltered: songsListFiltered
            };


        default:
            return state;
    }
}
