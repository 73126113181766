import React from "react"
import { useDispatch } from "react-redux"
import { saveSong } from "../redux/actions"
import { iChordsMap } from "../data/chords" // chords as allChords,
import transposeChords from "../helpers/transpose"

const SongTransposition = ({ song, chords, setChords }) => {
    const dispatch = useDispatch()

    const selectTransposition = k => {
        const transposeTo = parseInt(k)
        transpose(transposeTo)
        dispatch({
            type: "UPDATE_TRANSPOSITION",
            payload: { transposition: transposeTo, songId: song.id },
        })

        if (song.id) {
            // not for new , unsaved song 
            // saveSong({ ...song, transposition: transposeTo })
        }
    }

    const isKeySelected = key => {
        return key == song.transposition //song.tags.filter(tag => tag.id === tagId).length;
    }

    const transpose = transposeTo => {
        var moveBy = parseInt(transposeTo - parseInt(song.transposition))
        setChords(transposeChords({ ...chords }, moveBy))
    }

    return (
        <ul className="transposition nav-pills" id="trans">
            {Object.keys(iChordsMap).map((i, k) => (
                <li onClick={e => selectTransposition(i)} key={`trans-${iChordsMap[i]}`} title={`Trasponuj do tóniny: ${iChordsMap[i]}`}>
                    <button className={isKeySelected(i) ? "active" : ""}>{iChordsMap[i]}</button>
                </li>
            ))}
        </ul>
    )
}

export default SongTransposition
