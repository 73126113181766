import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, connect } from "react-redux";
// import isEmpty from 'lodash/isEmpty';
import { Redirect } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  getAllSongsFromState,
  getSongByRouteSlug,
  getTagsFromState,
  getInstrument,
  isUserLoggedIn,
  getUser,
  getUserPlaylist
} from "./redux/selectors";
import { saveSong, deleteSong, resetNewSong, addToPlaylist, removeFromToPlaylist, upVote, getSong } from "./redux/actions";
import useResize from "./hooks/useResize"

import { toast } from 'react-toastify';

/* Custom components */
import TopRow from "./components/user/TopRow";
import ChordsBar from "./components/ChordsBar";
import SongTags from "./components/SongTags";
import SongTransposition from "./components/SongTransposition";
import SongTitle from "./components/SongTitle";
import SelectInstument from "./components/SelectInstument";
import Voting from "./components/Voting";
import ShowAccord from "./components/ShowAccord";
import ShowAccords from "./components/ShowAccords";
import AddSongUsingUrl from "./components/AddSongUsingUrl";
import Modal from 'react-modal';

/* Custom hooks */
import useChordRow from "./hooks/chordRow";

var pixelWidth = require("string-pixel-width");

const Song = ({ props, songs, song, tagsList, instrument, loggedIn, user, playlist, saveSong, deleteSong, resetNewSong, addToPlaylist, removeFromToPlaylist, upVote, getSong }) => {

  const ENTER_KEY = 13;

  const songElement = useRef(null);
  //   const [isLoaded, setIsLoaded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addingMode, setAddingMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [songDetail, setSongDetail] = useState(false);
  const [songDetailInitialState, setSongDetailInitialState] = useState(false);

  const setAddingModeResetTags = (mode) => {
    setAddingMode(mode);
    setTags([]);
  };

  const resetAddingMode = () => {
    resetNewSong();
    setAddingMode(false)
  };
  const onParsed = (song) => {
    setAddingMode('manual')
    setChords(song.chords);
    setRows(song.rows);
    setSongTitle(song.name)
    setSongAuthor(song.author)
  };
  const [chords, setChords] = useState([]);
  const [rows, setRows] = useState([]);

  //active row 
  const [editingRow, setEditingRow] = useState(null);

  // is editable
  const [editable, setEditable] = useState(false);
  const [isMine, setIsMine] = useState(false);


  // titles 
  const [songTitle, setSongTitle] = useState('');
  const [songAuthor, setSongAuthor] = useState('');

  // modal 
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalVisibleClass, setModalVisibleClass] = useState('');

  // copy chords
  const [copiedChords, setCopiedChords] = useState([]);

  const {
    activeRow,
    chordsBar,
    defaultChord,
    onClick,
    resetChordsBar,
    hideChordBar
  } = useChordRow(editMode);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /* Redux dispatch */
  const dispatch = useDispatch();

  // responsive leftPadding



  const windowSize = useResize();
  const [leftPad, letLeftPad] = useState(0);

  useEffect(() => {
    (async () => {
      if (song.id && !song.rows) { // load details 
        const details = await getSong(song.id);
        setSongDetail(details)
        setSongDetailInitialState({ ...details })
        // update with full details 
        dispatch({ type: "UPDATE_CHORDS", payload: { songId: song.id, chords: details.chords } });
        dispatch({ type: "UPDATE_ROWS", payload: { songId: song.id, rows: details.rows } });

      } else {
        setSongDetail(song)
        setSongDetailInitialState({ ...song })

      }
    })();
  }, [song]);

  useEffect(() => {
    const el = document.getElementById("songbody");
    if (el) {
      letLeftPad(el.getBoundingClientRect().left + 15);
    }

  }, [windowSize]);

  useEffect(() => {

    //console.log(song, user)
    if (songDetail) {
      if (songDetail !== false && songDetail.rows) {
        setChords({ ...songDetail.chords });
        setRows([...songDetail.rows]);
        setSongTitle(songDetail.name)
        setSongAuthor(songDetail.author)
        if (songDetail.id) {
          addToHistory();
        } else { // new song
          setEditMode(true);
        }
      }

      if (songDetail.id === 0) {
        setAddingMode(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songDetail]);

  useEffect(() => {
    if (song && user/* && song.user_id === user.id*/) {
      setEditable(true);
      if (song.user_id === user.id) {
        setIsMine(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, song]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const el = document.getElementById('chordsBar');
      if (el && !el.contains(event.target)) {
        hideChordBar();
      }
    }

    const el = document.getElementById("songbody");
    if (el) {
      letLeftPad(el.getBoundingClientRect().left + 15);
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {

    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 500);
    window.addEventListener("resize", handleResize);

    // Bind the event listener

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const deleteChord = () => {
    let foundIndex = chords[activeRow.id].findIndex(
      item => item.position === activeRow.X
    );
    let chordsRow = [];
    if (foundIndex >= 0) {
      chordsRow = [...chords[activeRow.id]];
      chordsRow.splice(foundIndex, 1);
    }
    setChords({ ...chords, [activeRow.id]: chordsRow });
    resetChordsBar();
  };

  const startOver = () => {
    setEditMode(false)
    setSongDetail(songDetailInitialState)

    setChords(songDetailInitialState.chords);
    setRows(songDetailInitialState.rows);
    setSongTitle(songDetailInitialState.name)
    setSongAuthor(songDetailInitialState.author)
    setTags(songDetailInitialState.tags)

  };

  const saveChord = selectedChord => {
    const { chord, chordType } = selectedChord;
    const chordName = `${chord}${chordType}`;

    let chordsRow = [];
    if (typeof chords[activeRow.id] !== "undefined") {
      let foundIndex = chords[activeRow.id].findIndex(
        item => item.position === activeRow.X
      );
      if (foundIndex >= 0) {
        chordsRow = [...chords[activeRow.id]];
        chordsRow[foundIndex] = {
          chordName,
          chordType,
          chord,
          position: activeRow.X
        };
      } else {
        chordsRow = [
          ...chords[activeRow.id],
          { chordName, chordType, chord, position: activeRow.X }
        ];
      }
    } else {
      chordsRow = [{ chordName, chordType, chord, position: activeRow.X }];
    }
    setChords({ ...chords, [activeRow.id]: chordsRow });
    resetChordsBar();
  };

  /* Drag and drop */
  const [dndState, setDndState] = useState({
    isDragging: false,
    element: null,
    startDate: null
  });

  // mouse and touch move
  const handleMouseMove = useCallback(
    e => {
      if (editMode && dndState.isDragging) {
        let chordsRow = [];
        chordsRow = [...chords[dndState.element.row]];
        // console.log(e.clientX);

        chordsRow[dndState.element.index] = {
          ...chordsRow[dndState.element.index],
          position: (e.clientX ? e.clientX : e.touches[0].clientX) - leftPad
        };
        setChords({ ...chords, [dndState.element.row]: chordsRow });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dndState.isDragging]
  );

  // mouse left click release and touch stop event
  const handleMouseUp = useCallback(
    e => {
      if (!editMode) {
        return;
      }
      const date = new Date().getTime();
      const dateDiff = dndState.startDate ? date - dndState.startDate : null;
      if (dateDiff < 350) {
        if (e.target.id.includes("chord-") && dndState.element.chord) {
          onClick(e, dndState.element.chord, dndState.element.row, false);
        }
      }
      setDndState({
        ...dndState,
        isDragging: false,
        element: null,
        startDate: null
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dndState.isDragging]
  );

  // mouse left click hold and touch start event
  const handleMouseDown = useCallback(

    (e, chord, row, index) => {
      if (!editMode) {
        return;
      }
      e.stopPropagation();

      resetChordsBar();

      setDndState({
        ...dndState,
        isDragging: true,
        element: { chord, row, index },
        startDate: new Date().getTime()
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode]
  );

  // mouse hover to show accordf
  const handleMouseOver = useCallback(
    (e, chord, row, index) => {
      if (editMode) {
        return;
      }
      e.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode]
  );



  // use mouse events on window
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);


  /*********************/

  // copy chords

  const copyChords = (e, chords) => {
    e.preventDefault();
    e.stopPropagation();
    toast.success("Řádek zkopírován")
    setCopiedChords(chords);
  };
  const pasteChords = (e, activeRow) => {
    e.preventDefault();
    setChords({ ...chords, [activeRow.id]: copiedChords });
    toast.success("Řádek vložen")
  };

  /** add remove rows */
  const removeRow = (e, row) => {
    hideChordBar();
    const rowsCopy = [...rows];
    rowsCopy.splice(row, 1);
    setRows(rowsCopy);

    // remove chord row, decrement all next keys  
    var newChords = {};
    Object.keys(chords).forEach((el, k) => {
      const key = Number(k);
      if (key < row) {
        newChords[key] = chords[key];
      } else if (key > row) {
        newChords[key - 1] = chords[key];
      }
    });

    setChords(newChords);
  };

  const addRow = (e, row, text, addAbove = false) => {
    hideChordBar();

    const rowsCopy = [...rows];
    var newChords = {};

    addRowLogic(rowsCopy, newChords, row, text, addAbove);

    setRows(rowsCopy);
    setChords(newChords);

    setTimeout(() => {
      if (addAbove) {
        document.querySelector(`#songRow-input-${row}`).focus();
      } else {
        document.querySelector(`#songRow-input-${row + 1}`).focus();
      }

    }, 300);
  };

  const selectRow = (row, e) => {
    if (editMode) {
      const inp = document.querySelector(`#songRow-input-${row}`)
      inp.focus();
      const length = inp.value.length;
      inp.setSelectionRange(length, length);
      e.stopPropagation()
    }
  };

  const addRowLogic = (newRows, newChords, row, text, addAbove = false) => {
    text = text ? text : '';

    if (addAbove === true) {
      newRows.splice(row, 0, { text: text, length: 0 });
    } else {
      newRows.splice(row + 1, 0, { text: text, length: 0 });
    }
    if (addAbove === true) {
      // add chord row, increment all next keys  keep those before
      Object.keys(chords).forEach((el, k) => {
        const key = Number(k);
        if (key < row) {
          newChords[key] = chords[key];
        } else if (key >= row) {
          newChords[key + 1] = chords[key];
        }
      });
    } else {
      // add chord row, increment all next keys  
      Object.keys(chords).forEach((el, k) => {
        const key = Number(k);
        if (key < row + 1) {
          newChords[key] = chords[key];
        } else if (key > row) {
          newChords[key + 1] = chords[key];
        }
      });
    }
  };

  const handleKeyDown = (e, row) => {
    if (e.keyCode === ENTER_KEY) {
      addRow(e, row, '');
      e.preventDefault();
    }
  }

  const updateRow = (e, row) => {

    let val = String(e.target.value).trim();
    let lines = val.split(/[\r\n]+/);

    const rowsCopy = [...rows];

    if (lines.length > 1) { // paste multiple lines 
      for (let i = 0; i < lines.length; i++) {
        var newChords = {};
        addRowLogic(rowsCopy, newChords, row + i - 1, lines[i].trim());
        setChords(newChords);
      }
      // remove last one 
      rowsCopy.splice(row + lines.length, 1);
    } else { // single line add
      rowsCopy[row] = { ...rowsCopy[row], text: e.target.value };
    }
    setRows(rowsCopy);

  };


  const deleteSongById = async () => {

    const deleted = await deleteSong(song.id);
    if (deleted) {
      console.log("Succesfully deleted.")
    } else {
      console.warn("Error deleting song.")
    }
    setEditMode(false);

    props.history.push("/");
  };

  const save = async () => {
    hideChordBar();
    setSaving(true)
    toast.info('Ukládání...')
    const addingNewSong = song.id === 0 || (song.user_id !== user.id);

    const newSong = await saveSong({ ...song, chords: chords, rows: rows, name: songTitle, author: songAuthor, tags: tags });

    dispatch({ type: "UPDATE_TAGS", payload: { tags: tags, songId: newSong.id } })
    dispatch({ type: "UPDATE_CHORDS", payload: { songId: newSong.id, chords } });
    dispatch({ type: "UPDATE_ROWS", payload: { songId: newSong.id, rows } });
    dispatch({ type: "UPDATE_NAME", payload: { songId: newSong.id, name: songTitle } });
    dispatch({ type: "UPDATE_AUTHOR", payload: { songId: newSong.id, author: songAuthor } });


    if (addingNewSong) {
      addToPlaylist(newSong);
      if (song.id) { // copy from original 
        upVote(song);
      }
    }

    if (newSong && addingNewSong) {
      setTimeout(() => {
        props.history.push("/pisnicka/" + newSong.slug);
      }, 0);
      //return <Redirect to={"/song/" + newSongId} />;
    } else {
      setEditMode(false);
    }

    toast.success('Písnička uložena')
    setSaving(false)
  };

  /* tags */

  const [tags, setTags] = useState(song.tags);

  const addRemoveTag = tagId => {

    if (!user || !editMode) {
      return false;
    }


    var tagsUpdated
    if (tags.filter(item => item.id === tagId).length) {
      // exists so remove
      tagsUpdated = [...tags].filter(item => item.id !== tagId)
    } else {
      // does not exist, add
      tagsUpdated = [...tags, tagsList.filter(item => item.id === tagId)[0]]
    }

    console.log(tagsUpdated)
    setTags(tagsUpdated);
    /*
    let data = { ...song }
    data.tags = tagsUpdated

    dispatch({
        type: "UPDATE_TAGS",
        payload: { tags: tagsUpdated, songId: song.id },
    })
    if (song.id) {
        // not for new , unsaved song 
        saveSong(data)
    }
    */
  }

  const isTagSelected = tagId => {
    if (!song || !tags) {
      return false
    }
    return tags.filter(tag => tag.id === tagId).length
  }

  /* History */
  const addToHistory = () => {
    dispatch({ type: "ADD_TO_HISTORY", payload: { songId: song.id } });
  };

  if (song === false) {
    // not found go home
    return <Redirect to="/pisnicky" />;
  }


  /* Modals */
  const handleOpenModal = () => {
    setShowConfirmModal(true);
    setTimeout(() => {
      setModalVisibleClass('visible');
    }, 100);

  }

  const handleCloseModal = () => {
    setModalVisibleClass('');
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 100);
  }


  /* playlist */
  const addToMyPlaylist = () => {
    addToPlaylist(song);
  };

  const removeFromMyPlaylist = () => {
    removeFromToPlaylist(song);
  };



  /*
  // not working, we need to keep the song body ref
  // add new switch 
  if (song.id === 0 && !addingMode) {
      return (
          <div id="singleSong">

              <NavLink to={"/"} id="back-link">Zpět na seznam</NavLink>
              <div className="title"><h1>Přidat písničku</h1></div>


              <div className="addNewUsing">
                  <button onClick={e => setAddingMode('manual')}>Manuálně</button>
                  <button onClick={e => setAddingMode('url')}>Zadáním URL</button>
              </div>
          </div>
      )
  }

  //add using url
  if (song.id === 0 && addingMode === 'url') {
      return (
          <div id="singleSong" >

              <NavLink to={"/"} id="back-link">Zpět na seznam</NavLink>

              <div className="title"><h1>Přidat písničku</h1></div>

              <AddSongUsingUrl song={song} onParsed={onParsed} />

          </div>
      )
  }
  */

  return (
    <React.Fragment>

      {addingMode ? <TopRow backCallBack={resetAddingMode} backTitle="Zpět" /> : <TopRow back="/pisnicky" backTitle="Zpět na seznam" />}

      <p className="source">zdroj: {window.location.href}</p>

      <div onClick={(e) => setEditingRow(null)} id="singleSong" className={`${editMode ? "editMode" : ""} ${saving ? "saving" : ""} ${(addingMode === 'manual' || song.id > 0) ? "body-visible" : ""}`}>
        {/* {windowSize.width} */}

        {(song.id === 0 && addingMode === 'url') &&
          <React.Fragment>
            <div className="title"><h1>Přidat písničku</h1></div>
            <AddSongUsingUrl song={song} onParsed={onParsed} />
          </React.Fragment>
        }

        {(song.id === 0 && addingMode === false) &&
          <React.Fragment>
            <div className="title"><h1>Přidat písničku</h1></div>
            {
              user &&
              <div className="addNewUsing">
                <div className="crossroads">
                  <button className="button big b" onClick={e => setAddingModeResetTags('manual')}>Manuálně</button>
                  <button className="button big b" onClick={e => setAddingModeResetTags('url')}>Zadáním URL</button>
                </div>
              </div>
            }

            {
              !user &&
              <div className="addNewUsing">
                <h2>Nejprve se prosím přihlašte</h2>
              </div>
            }

          </React.Fragment>
        }

        {(addingMode === 'manual' || song.id > 0) &&
          <React.Fragment>

            {(editable) && <div className="edit-toolbar">
              {!editMode ?
                (
                  <React.Fragment>
                    <button className="edit fill" onClick={() => setEditMode(true)}>Upravit</button>
                    {playlist.indexOf(song.id) === -1 && <button className="add-to-playlist fill" onClick={() => addToMyPlaylist()}>Přidat do mého zpěvníku</button>}
                    {playlist.indexOf(song.id) > -1 && <button className="remove fill" onClick={() => removeFromMyPlaylist()}>Odebrat z mého zpěvníku</button>}
                  </React.Fragment>

                ) : (
                  <React.Fragment>
                    {song.id > 0 && <button className="remove fill" onClick={handleOpenModal}>Smazat písničku</button>}
                    {song.id > 0 && <button className="cancel fill" onClick={() => startOver(false)}>Zrušit úpravy</button>}
                    <button className="save fill b" onClick={save}>Uložit písničku</button>
                  </React.Fragment>
                )}
            </div>
            }

            <Modal
              isOpen={showConfirmModal}
              onRequestClose={handleCloseModal}
              className={`modal ${modalVisibleClass}`}
              overlayClassName="overlay"
            >
              <div class="content">
                <a href="#" className="close" onClick={handleCloseModal}></a>
                <div class="inner-content">
                  <h3>Skladba bude nanávratně smazána</h3>
                  <p>Chcete pokračovat?</p>

                  <div className="btns">
                    <button className="confirm-delete" onClick={deleteSongById}>Ano</button>
                    <button className="reject" onClick={handleCloseModal}>Ne</button>
                  </div>

                </div>
              </div>
            </Modal>


            <SongTitle song={song} songTitle={songTitle} setSongTitle={setSongTitle} songAuthor={songAuthor} setSongAuthor={setSongAuthor} editMode={editMode} />

            <SongTags editMode={editMode} addRemoveTag={addRemoveTag} isTagSelected={isTagSelected} {...props} />

            <div className="centered-cols">
              <div className="left-col">
                <div className="instrument-votes">
                  <SelectInstument song={song} instrument={instrument} />
                  {!isMine && <Voting song={song} user={user} />}
                </div>
                <SongTransposition song={song} chords={chords} setChords={setChords} />
              </div>
              <div className="right-col"></div>
            </div>
          </React.Fragment>
        }


        <div className="centered-cols">
          <div className="left-col">
            <div
              id="songbody"
              ref={songElement}
            >

              <ChordsBar
                chordsBar={chordsBar}
                activeRow={activeRow}
                defaultChord={defaultChord}
                deleteChord={deleteChord}
                saveChord={saveChord}
                pasteChords={pasteChords}
              />
              {rows &&
                rows.map((row, key) => {
                  /* TODO: solve tollerance - 20px for now */
                  const tolerance = 20;
                  const subrowsCount = songElement.current
                    ? parseInt(
                      row.length / (songElement.current.offsetWidth - tolerance),
                      10
                    )
                    : 1;
                  const lineHeight = 24;
                  const leftPadding = 0;// smallPad ? 30 : 50;
                  if (!row.text) {
                    row.text = '';
                  }
                  const words = row.text.split(" ");
                  return (
                    <div className="songRowWrap" key={key} onClick={e => selectRow(key, e)}>
                      <div
                        className={`chordsRow ${editMode ? "greyBg" : "defaultMode"}`}
                        id={`chordsRow-${key}`}
                        onClick={e => { selectRow(key, e); onClick(e); }}
                      >
                        {chords &&
                          chords[key] &&
                          chords[key].map((chord, id) => {
                            const changePosition =
                              subrowsCount > 0 &&
                              chord.position >
                              songElement.current.offsetWidth - tolerance;
                            const chordRowNumber = songElement.current
                              ? parseInt(
                                chord.position /
                                (songElement.current.offsetWidth - tolerance),
                                10
                              )
                              : 1;
                            const songWidth = songElement.current
                              ? songElement.current.offsetWidth - leftPadding
                              : 0;
                            let left = chord.position;
                            let top = 0;
                            if (!editMode && changePosition && windowWidth) {

                              // what why?
                              let j = 0;
                              let sum = pixelWidth(words[0] + " ", {
                                size: 16,
                                font: "Andale Mono"
                              });
                              let subRowsLengths = [];
                              for (let i = 0; i < words.length; i++) {
                                let tempSum =
                                  sum +
                                  pixelWidth(words[i + 1] + " ", {
                                    size: 16,
                                    font: "Andale Mono"
                                  });
                                if (tempSum > songWidth) {
                                  subRowsLengths[j] = sum;
                                  j++;
                                  sum = 0;
                                } else {
                                  sum = tempSum;
                                }
                              }

                              left =
                                chord.position - 5 -
                                songElement.current.offsetWidth * chordRowNumber +
                                leftPadding +
                                (songWidth - subRowsLengths[chordRowNumber - 1]); // what why?
                              top = chordRowNumber * lineHeight;
                              if (left < leftPadding) {
                                left = leftPadding;
                              }
                            }
                            return (
                              <span
                                style={{ left: `${left}px`, top: `${top}px` }}
                                className="chord"
                                key={`chord-${id}`}
                                id={`chord-${id}`}
                                onMouseOver={e => handleMouseOver(e, chord, key, id)}
                                onMouseDown={e => handleMouseDown(e, chord, key, id)}
                                onTouchStart={e => {
                                  e.preventDefault();
                                  handleMouseDown(e, chord, key, id);
                                }}
                                onTouchMove={e => {
                                  e.preventDefault();
                                  handleMouseMove(e);
                                }}
                                onTouchEnd={e => {
                                  e.preventDefault();
                                  handleMouseUp(e);
                                }}
                              >
                                {chord.chordName}
                                <ShowAccord key={`chord-accord-${id}`} chordName={chord.chordName} instrument={instrument} />
                              </span>
                            );
                          })}
                        {(chords && chords[key]) && <i className="copy-row" onClick={e => copyChords(e, chords[key])} title="Kopírovat akordy"></i>}
                      </div>
                      <div className={`songRow ${editingRow === key ? 'active' : ''}`}>
                        {!editMode ? (
                          words.map((word, wordKey) => (
                            <span key={`word-${wordKey}`}>{word} </span>
                          ))
                        ) : (
                          <textarea
                            value={row.text}
                            name={`songRow-${key}`}
                            placeholder="Začněte psát"
                            autoFocus={key === 0}
                            className="lightGreyBg"
                            id={`songRow-input-${key}`}
                            onPaste={e => updateRow(e, key)}
                            onChange={e => updateRow(e, key)}
                            onKeyDown={(e) => handleKeyDown(e, key)}
                            onFocus={(e) => setEditingRow(key)}
                            /* onBlur={(e) => setEditingRow(null)} */
                            style={{ width: `${row.text.length + 5}ch` }}
                            rows="1"
                          />
                        )}
                        <i className="addRow up" onClick={e => addRow(e, key, '', true)} title="Přidat řádek nad"></i>
                        <i className="addRow down" onClick={e => addRow(e, key, '', false)} title="Přidat řádek pod"></i>
                        <i className="removeRow" onClick={e => removeRow(e, key)} title="Odebrat řádek"></i>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="right-col">
            <ShowAccords chords={chords} instrument={instrument} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  props: props,
  songs: getAllSongsFromState(state),
  song: getSongByRouteSlug(state, props),
  tagsList: getTagsFromState(state),
  instrument: getInstrument(state),
  loggedIn: isUserLoggedIn(state),
  user: getUser(state),
  playlist: getUserPlaylist(state)
});

export default connect(mapStateToProps, { saveSong, deleteSong, resetNewSong, addToPlaylist, removeFromToPlaylist, upVote, getSong })(Song);
