const initialState = {
    version: 1
}

export default function (state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
