import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { filterSongsByLetter } from "../redux/actions"
import { getAllSongsListGrouped, getLetterByRouteSlug } from "../redux/selectors"
import { NavLink } from "react-router-dom"
import useFilterLocation from "../hooks/useFilterLocation";

const Alphabet = ({ props, letter, songs, filterSongsByLetter }) => {
    const [selectedLetter, setSelectedLetter] = useState(letter)
    const { basePath, allPath } = useFilterLocation();

    useEffect(() => {
        setSelectedLetter(letter)

        if (localStorage.getItem('changing_tag') === "1") {
            // already reseted by changing tag, prevent duplicate dispatch
        } else {
            filterSongsByLetter(letter)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [letter])

    const secondClick = (isActive, event) => {

        localStorage.setItem('changing_letter', "1")
        localStorage.setItem('changing_tag', "0")

        if (isActive) {
            // second click to reset
            event.preventDefault()
            setSelectedLetter("")
            filterSongsByLetter("")
            props.history.push(allPath)
        }
    }

    return (
        <div className="alphabet_wrap">
            <ul className="nav nav-pills inline" id="IntTab">
                <li className={!selectedLetter ? "active" : ""}>
                    <NavLink to={allPath} id="letter-trigger-all" data-toggle="tab">
                        VŠE
                    </NavLink>
                </li>

                {songs.map(grouped => (
                    <li key={grouped.group}>
                        <NavLink
                            to={`${basePath}od-pismene/${grouped.group}`}
                            onClick={e => secondClick(selectedLetter === grouped.group, e)}
                            key={`letter-${grouped.group}`}
                            id={`letter-trigger-${grouped.group}`}
                            data-toggle="tab"
                            className={selectedLetter === grouped.group ? "active" : ""}
                        >
                            {grouped.group}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}
const mapStateToProps = (state, props) => ({
    props: props,
    letter: getLetterByRouteSlug(state, props),
    songs: getAllSongsListGrouped(state),
})

export default connect(mapStateToProps, { filterSongsByLetter })(Alphabet)
