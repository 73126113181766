import React from "react"
import { NavLink } from "react-router-dom"
import Votes from "../components/Votes"
import { addToPlaylist, removeFromToPlaylist } from "../redux/actions"
import { connect } from "react-redux"

const SongRow = ({ song, selectedTag, user, playlist, addToPlaylist, removeFromToPlaylist, expandable, expandCoplapse }) => {
  const add = song => {
    addToPlaylist(song)
  }

  const remove = song => {
    removeFromToPlaylist(song)
  }

  if (!song) {
    return '';
  }

  return (
    <div className="song-row">
      <NavLink title={`${song.name} - ${song.author}`} to={"/pisnicka/" + song.slug} className="song-link">
        <span className="expander">
          {expandable && <span className="expand" onClick={e => expandCoplapse(e)}></span>}
        </span>
        <span className="item-title">{song.name}</span>
        <span className="item-author">{song.author}</span>
        <span className="item-user">@{song.user_name}</span>
        <span className="item-likes">
          <Votes song={song} />
        </span>
        <span className="tags-wrap">
          <div className="tags transparent">
            {song.tags.map(tag => (
              <span className={`tag ${selectedTag === tag.id ? "selected" : ""}`} key={`tag-${tag.id}-${song.id}`}>
                {tag.title}
              </span>
            ))}
          </div>
        </span>
      </NavLink>
      {user && (
        <span className="row_actions">
          {playlist.indexOf(song.id) === -1 && <span className="add" onClick={e => add(song)}></span>}
          {playlist.indexOf(song.id) > -1 && <span className="delete" onClick={e => remove(song)}></span>}
        </span>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  props: props,
  song: props.song,
  selectedTag: props.selectedTag,
  user: props.user,
})

export default connect(mapStateToProps, { addToPlaylist, removeFromToPlaylist })(SongRow)
