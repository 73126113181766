import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import { getSongsListGrouped, getSongGroups, getTagByRouteSlug, getUserPlaylist, getSongsCount } from "./redux/selectors"
//import { NavLink } from 'react-router-dom';

import { isUserLoggedIn, getUser } from "./redux/selectors"

/* Custom components */
import TopRow from "./components/user/TopRow"
import Search from "./components/Search"
import Alphabet from "./components/Alphabet"
import SongsGroup from "./components/SongsGroup"
import SongsCount from "./components/SongsCount"

const Songs = ({ props, songs, songsCount, songGroups, tag: selectedTag, loggedIn, user, playlist }) => {
    /// deconstruct the state

    var timer = useRef(false)
    const WAIT_INTERVAL = 50

    const saveScrollPosition = () => {
        localStorage.setItem("last_scroll", window.scrollY)
    }

    const scrolling = () => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            saveScrollPosition()
        }, WAIT_INTERVAL)
    }

    useEffect(() => {
        window.addEventListener("scroll", scrolling)

        var scrollTo = localStorage.getItem("last_scroll")
        scrollTo = scrollTo ? Number(scrollTo) : 0

        window.scrollTo({
            top: scrollTo,
            behavior: "auto",
        })

        return () => {
            window.removeEventListener("scroll", scrolling)
            if (timer && timer.current) {
                clearTimeout(timer.current)
            }
        }
    })

    return (
        <React.Fragment>
            { loggedIn ? <TopRow /> : <TopRow back="/" backTitle="Zpět" />}


            <Search {...props} />
            <Alphabet {...props} />

            <div className="tab-content" id="IntTabContent">

                <SongsCount count={songsCount} />

                {songs.map(grouped => (
                    <div className="tab-pane" key={grouped.group} id={`letter-${grouped.group}`}>
                        <h2>{grouped.group}</h2>
                        <ul className={`itemlist ${loggedIn ? "logged-in" : ""}`}>
                            {grouped.children.map(song => (
                                <SongsGroup key={`song-${song.id}`} song={song} selectedTag={selectedTag} user={user} playlist={playlist} songGroups={songGroups} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state, props) => ({
    props: props,
    songs: getSongsListGrouped(state, props),
    songsCount: getSongsCount(state, props),
    songGroups: getSongGroups(state, props),
    tag: getTagByRouteSlug(state, props),
    loggedIn: isUserLoggedIn(state),
    user: getUser(state),
    playlist: getUserPlaylist(state),
})

export default connect(mapStateToProps)(Songs)
