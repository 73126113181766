import React from 'react';

const Votes = ({ song }) => {

    return (
        <div className="votes">
            {song.votes_up}
        </div>
    );
}

export default Votes;