export const chords = [
	'C',
	'C#',
	'D',
	'Es',
	'E',
	'F',
	'F#',
	'G',
	'As',
	'A',
	'B',
	'H',
	//  'C', 'D', 'E', 'F', 'G', 'A', 'H'
]

export const chordsMap = { C: 0, 'C#': 1, Des: 1, D: 2, 'D#': 3, Es: 3, E: 4, F: 5, 'F#': 6, Ges: 6, G: 7, 'G#': 8, As: 8, A: 9, 'A#': 10, B: 10, Bb: 10, H: 11 }
export const iChordsMap = { 0: 'C', 1: 'C#', 2: 'D', 3: 'Es', 4: 'E', 5: 'F', 6: 'F#', 7: 'G', 8: 'As', 9: 'A', 10: 'B', 11: 'H' }

export const chordTypes = [
	{ key: '', value: 'dur' },
	{ key: 'm', value: 'moll' },
	{ key: '7', value: '7' },
	{ key: 'm7', value: 'm7' },
	{ key: 'maj', value: 'maj' },
	{ key: 'maj7', value: 'maj7' },
	//{ key: "#", value: "#" }
]

export const instruments = [
	{ key: 'guitar', name: 'Kytara' },
	{ key: 'ukulele', name: 'Ukulele' },
]

//= mi = m

// guitar
export const accords = {
	guitar: {
		C: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		C7: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 1, 0, 1, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Cmaj7: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		D: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 1, 0, 1],
			[0, 0, 0, 0, 1, 0],
			[0, 0, 0, 0, 0, 0],
		],
		D7: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 0, 1, 0, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Dm: [
			[0, 0, 0, 0, 0, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 0, 0, 0, 1, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Dm7: [
			[0, 0, 0, 0, 1, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Dmaj7: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 1, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		E: [
			[0, 0, 0, 1, 0, 0],
			[0, 1, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		E7: [
			[0, 0, 0, 1, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Em: [
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Em7: [
			[0, 0, 0, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		F: [
			[0, 0, 0, 0, 1, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Fmaj7: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 0, 1, 0, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		G: [
			[0, 0, 0, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[1, 0, 0, 0, 0, 1],
			[0, 0, 0, 0, 0, 0],
		],
		G7: [
			[0, 0, 0, 0, 0, 1],
			[0, 1, 0, 0, 0, 0],
			[1, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		A: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 1, 1, 1, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		A7: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 1, 0, 1, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Am: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 1, 1, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Am7: [
			[0, 0, 0, 0, 1, 0],
			[0, 0, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Amaj7: [
			[0, 0, 0, 1, 0, 0],
			[0, 0, 1, 0, 1, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		B: [
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 1, 1, 1, 0],
		],
		H: [
			[0, 1, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 1, 1, 1, 0],
		],
		B7: [
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Bm: [
			[0, 0, 1, 0, 0, 0],
			[0, 1, 0, 1, 0, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		Hm: [
			[0, 1, 1, 1, 1, 1],
			[0, 0, 0, 0, 1, 0],
			[0, 0, 1, 1, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		//# + special
		'C#': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'C#m': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'D#': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'D#m': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'F#': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 1, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'F#m': [
			[0, 0, 0, 0, 0, 0],
			[1, 0, 0, 0, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
		],
		'G#': [
			[0, 0, 1, 1, 1, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[1, 0, 0, 0, 0, 1],
		],
		'G#m': [
			[1, 0, 0, 1, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
		As: [
			[0, 0, 1, 1, 1, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 0, 0, 0, 0],
			[1, 0, 0, 0, 0, 1],
		],
		Asm: [
			[1, 0, 0, 1, 1, 1],
			[0, 0, 0, 0, 0, 0],
			[0, 1, 1, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
		],
	},
	ukulele: {
		C: [
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 0, 0],
		],
		D: [
			[0, 0, 0, 0],
			[1, 1, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		E: [
			[0, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 0, 0],
			[1, 1, 1, 0],
		],
		F: [
			[0, 0, 1, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		G: [
			[0, 0, 0, 0],
			[0, 1, 0, 1],
			[0, 0, 1, 0],
			[0, 0, 0, 0],
		],
		A: [
			[0, 1, 0, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		B: [
			[0, 0, 0, 0],
			[0, 0, 1, 1],
			[0, 1, 0, 0],
			[1, 0, 0, 0],
		],
		H: [
			[0, 0, 0, 0],
			[0, 0, 1, 1],
			[0, 1, 0, 0],
			[1, 0, 0, 0],
		],

		Cm: [
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 1, 1, 1],
			[0, 0, 0, 0],
		],
		Dm: [
			[0, 0, 1, 0],
			[1, 1, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Em: [
			[0, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 1, 0],
			[0, 1, 0, 0],
		],
		Fm: [
			[1, 0, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 0, 0],
		],
		Gm: [
			[0, 0, 0, 1],
			[0, 1, 0, 0],
			[0, 0, 1, 0],
			[0, 0, 0, 0],
		],
		Am: [
			[0, 0, 0, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Bm: [
			[0, 0, 0, 0],
			[0, 1, 1, 1],
			[0, 0, 0, 0],
			[1, 0, 0, 0],
		],
		Hm: [
			[0, 0, 0, 0],
			[0, 1, 1, 1],
			[0, 0, 0, 0],
			[1, 0, 0, 0],
		],
		C7: [
			[0, 0, 0, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		D7: [
			[0, 0, 0, 0],
			[1, 0, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		E7: [
			[0, 0, 0, 0],
			[0, 1, 0, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		F7: [
			[0, 0, 1, 0],
			[1, 0, 0, 0],
			[0, 1, 0, 0],
			[0, 0, 0, 0],
		],
		G7: [
			[0, 0, 1, 0],
			[0, 1, 0, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		A7: [
			[0, 1, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		B7: [
			[0, 0, 0, 0],
			[1, 0, 1, 1],
			[0, 1, 0, 0],
			[0, 0, 0, 0],
		],
		H7: [
			[0, 0, 0, 0],
			[1, 0, 1, 1],
			[0, 1, 0, 0],
			[0, 0, 0, 0],
		],

		Cmaj7: [
			[0, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Dmaj7: [
			[0, 0, 0, 0],
			[1, 1, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 1],
		],
		Emaj7: [
			[1, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 1, 0, 0],
			[0, 0, 0, 0],
		],
		Fmaj7: [
			[0, 0, 1, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 1, 0, 0],
		],
		Gmaj7: [
			[0, 0, 0, 0],
			[0, 1, 1, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Amaj7: [
			[1, 1, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Bmaj7: [
			[0, 0, 0, 0],
			[0, 0, 1, 1],
			[1, 1, 0, 0],
			[0, 0, 0, 0],
		],
		Hmaj7: [
			[0, 0, 0, 0],
			[0, 0, 1, 1],
			[1, 1, 0, 0],
			[0, 0, 0, 0],
		],

		Cm7: [
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[1, 1, 1, 1],
			[0, 0, 0, 0],
		],
		Dm7: [
			[0, 0, 1, 0],
			[1, 1, 0, 0],
			[0, 0, 0, 1],
			[0, 0, 0, 0],
		],
		Em7: [
			[0, 0, 0, 0],
			[0, 1, 0, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Fm7: [
			[1, 0, 1, 0],
			[0, 0, 0, 0],
			[0, 1, 0, 1],
			[0, 0, 0, 0],
		],
		Gm7: [
			[0, 0, 1, 1],
			[0, 1, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Am7: [
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 1, 1],
			[0, 1, 0, 0],
		],
		Bm7: [
			[0, 0, 0, 0],
			[1, 1, 1, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Hm7: [
			[0, 0, 0, 0],
			[1, 1, 1, 1],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],

		//# + special
		'F#m': [
			[0, 1, 0, 0],
			[1, 0, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
		],
		'A#': [
			[0, 0, 1, 1],
			[0, 1, 0, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 0],
		],
		'C#m': [
			[1, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 1, 1, 1],
		],
		'F#': [
			[0, 1, 0, 1],
			[0, 0, 1, 0],
			[1, 0, 0, 0],
			[0, 0, 0, 0],
		],
		Abm: [
			[1, 0, 0, 0],
			[0, 0, 0, 1],
			[0, 2, 0, 0],
			[0, 0, 3, 0],
		],
		'D#': [
			[0, 0, 0, 1],
			[0, 0, 0, 0],
			[0, 1, 1, 0],
			[0, 0, 0, 0],
		],
		'C#': [
			[1, 1, 1, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 0],
			[0, 0, 0, 1],
		],
	},
}
