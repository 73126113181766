import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { getPublicUserSongsList, getUser, getTagByRouteSlug, getPublicUserSongsCount } from "./redux/selectors"
import { updateUser, deleteUser, getUserByUrl } from "./redux/actions"
import useFilterLocation from "./hooks/useFilterLocation";

/* Custom components */
import TopRow from "./components/user/TopRow"
import SongRow from "./components/SongRow"
import Search from "./components/Search"
import Alphabet from "./components/Alphabet"
import SongsCount from "./components/SongsCount"
import NotFound from "./NotFound"


const UserProfile = ({ props, songs, songsCount, tag: selectedTag, getUserByUrl }) => {
    /// deconstruct the state

    const { basePath, allPath } = useFilterLocation();

    const [profileNamePublic, setProfileNamePublic] = useState('')
    const [notFound, setNotFound] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        // get user by public url 
        const parts = basePath.split('/');

        (async () => {
            var info = await getUserByUrl(parts[1]);

            if (info && info.user) {
                setProfileNamePublic(info.user.name);
            } else {
                setProfileNamePublic('Uživatel nenalezen');
                setNotFound(true);
            }

            setLoaded(true);
        })();
    }, [])


    if (loaded && notFound) {
        return <NotFound />
    }


    return loaded ? (
        <React.Fragment>
            <TopRow back="/pisnicky" backTitle="Zpět" />

            <div className="title" id="header">
                <h1>Zpěvník uživatele {profileNamePublic}</h1>
            </div>

            <Search {...props} baseUrl={true} />
            <Alphabet {...props} baseUrl={true} />

            <div className="tab-content">

                <SongsCount count={songsCount} />

                {songs.map(grouped => (
                    <div className="tab-pane" key={grouped.group} id={`letter-${grouped.group}`}>
                        <h2>{grouped.group}</h2>
                        <ul className={`itemlist`}>
                            {grouped.children.map(song => (
                                <SongRow key={`song-${song.id}`} song={song} selectedTag={selectedTag} user={false} playlist={false} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </React.Fragment>
    ) : ''
}
const mapStateToProps = (state, props) => ({
    props: props,
    user: getUser(state),
    songs: getPublicUserSongsList(state, props),
    songsCount: getPublicUserSongsCount(state, props),
    tag: getTagByRouteSlug(state, props),
})

export default connect(mapStateToProps, { updateUser, deleteUser, getUserByUrl })(UserProfile)
