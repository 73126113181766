import React from 'react'

const Footer = ({ songs }) => {
	return (
		<footer>
			<a href="/" className="logo">
				Můj zpěvník
			</a>
			<p className="love">
				Vytvořeno s <span>love</span> v{' '}
				<a href="https://www.code8.cz/" target="_blank" rel="noopener noreferrer">
					code8.cz
				</a>{' '}
			</p>
			<p className="version">
				BETA 0.{process.env.REACT_APP_VERSION} ({songs.length} písní)
			</p>
		</footer>
	)
}
export default Footer
