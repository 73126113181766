import { chordsMap, iChordsMap } from "../data/chords"; // chords as allChords,

const transposeChords = (chordsCopy, moveBy) => {

    var newChord = "";

    Object.keys(chordsCopy).map((i, k) => {
        let row = chordsCopy[i];
        for (let x = 0; x < row.length; x++) {
            let ch = row[x];
            let el = ch.chord;
            if (el.length) {
                let idx;
                if (el.substr(0, 2) in chordsMap && el.length > 1) {
                    // test 2 letters
                    // alert(parseInt(pole_akordy[el.substr(0,2)]));
                    idx = parseInt(chordsMap[el.substr(0, 2)]) + moveBy;
                    if (idx < 0) {
                        idx = idx + 12;
                    }
                    if (idx > 11) {
                        idx = idx - 12;
                    }
                    newChord = iChordsMap[idx] + el.substr(2);
                } else if (el.substr(0, 1) in chordsMap) {
                    // test 1 letter
                    idx = parseInt(chordsMap[el.substr(0, 1)]) + moveBy;
                    if (idx < 0) {
                        idx = idx + 12;
                    }
                    if (idx > 11) {
                        idx = idx - 12;
                    }
                    newChord = iChordsMap[idx] + el.substr(1);
                }
                //console.log('orig_akord: ' + el + ' novy akord: ' + newChord);
                ch.chord = newChord;
                ch.chordType = ch.chordType ? ch.chordType : "";
                ch.chordName = `${ch.chord}${ch.chordType}`;
                //let chchordsCopy[i][x]
            }
        }
        return null;
    });

    return chordsCopy;
}
export default transposeChords;