import React, { useState, useEffect } from 'react';

/* Static data */
import { chords, chordTypes as allChordTypes } from '../data/chords';

const ChordsBar = ({ chordsBar, activeRow, defaultChord, deleteChord, saveChord, pasteChords }) => {
  const [selectedChord, setSelectedChord] = useState(defaultChord.chord);

  useEffect(() => {
    setSelectedChord(defaultChord.chord);
  }, [defaultChord]);

  const updateChord = (chordType) => {
    saveChord({ chord: selectedChord, chordType });
    setSelectedChord(null);
  }

  const selectChord = (chord) => {
    if (chord === selectedChord) {
      updateChord(''); // dur by default
    } else {
      setSelectedChord(chord);
    }
  }


  return (
    <div className="chordsBar" id="chordsBar">
      <div className={`chords_arrow ${!chordsBar.isVisible ? "hidden" : ""}`} style={{ bottom: `${activeRow.Y + 5}px`, left: `${activeRow.X}px` }}></div>
      <div className={`chords ${!chordsBar.isVisible ? "hidden" : ""}`} style={{ bottom: `${activeRow.Y + 15}px` }}>
        <div className={`chordTypes ${!chordsBar.isVisible || selectedChord === null ? "hidden" : ""}`}>
          {allChordTypes.map((type, i) => <span className={`${defaultChord.chordType === type.key ? "active" : ""}`} onClick={() => updateChord(type.key)} key={`chordType-${i}`}>{type.value}</span>)}
          {chordsBar.showDelete && <span className="close" onClick={deleteChord}>x</span>}
        </div>
        <div className="chordNotes">
          {chords.map((chord, i) => <span className={`${selectedChord === chord ? "active" : ""}`} onClick={() => selectChord(chord)} key={`allchord-${i}`}>{chord}</span>)}
          <div className="pasteChords">
            <span className="paste-row" onClick={e => pasteChords(e, activeRow)} title="Vložit akordy"></span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChordsBar;